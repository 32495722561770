<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">用户列表</div>
        <TableComponent :tableObjet="tableObjet" :headers="headers" :height="750">

            <template #backlist="item">

                <!-- <el-switch v-model="item.row.backlist" disabled
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" /> -->
                <el-tag v-if="item.row.backlist" style="color: red;">
                    {{ item.row.backlist ? "禁用" : "正常" }}
                </el-tag>
                <el-tag v-else style="color: green;">
                    {{ item.row.backlist ? "禁用" : "正常" }}
                </el-tag>
            </template>
            <template #v_state="item">
                <el-tag>{{ getVState(item.row.v_state) }}</el-tag>
            </template>
            <template #caozuo="item">

                <el-dropdown>
                    <el-button type="primary">
                        操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>

                                <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" 
                                    icon-color="#626AEF" title="是否确定?" @confirm="addBacklist(item.row)"
                                    >
                                    <template #reference>

                                        {{ (item.row.backlist ? '解除黑名单' : '加入黑名单') }}
                                    </template>
                                </el-popconfirm>

                            </el-dropdown-item>

                        </el-dropdown-menu>
                    </template>
                </el-dropdown>



                <!-- <el-button type="info" icon="Document" circle @click="handleInfo(scope.$index, scope.row)" />
                <el-button @click="handleEdit(scope.$index, scope.row)" type="primary" icon="Edit" circle />
                <el-button type="danger" icon="Delete" circle @click="handleDelete(scope.$index, scope.row)" /> -->
            </template>
        </TableComponent>

    </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import { useUserIndex, useAddBackList } from "@/use/user"
import XIASearchBar from "@/components/XIA-SearchBar.vue";
const { searchData, tableData, headers, dataCount, currPage, search, tableObjet } = useUserIndex();
const { addBacklist } = useAddBackList();
const getVState = (state) => {
    const states = ['未提交', '待审核', '已审核']
    return states[state]
}
</script>

<style lang="scss" scoped></style>