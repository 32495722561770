<template>
    <XIASearchBar :searchData="searchData" @search="search"/>
    <div class="title">
       租赁排行榜
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

    </TableComponent>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {usePH} from "@/use/user"

const {tableObjet,search,searchData,headers}=usePH()
</script>