import {reactive} from "vue"
import {useBase} from "@/use/base"
import {getOverView} from "@/api/finance"
export function useOverView(){

    const searchData=reactive({
        create_time:{
            type:"datePick",
            value:"",
            label:"选择日期"
        }
    })
    const headers=[
        {
            label:"id",
            prop:"id"
        },
        {
            label:"门店前缀",
            prop:"pre_name"
        },
        {
            label:"门店",
            prop:"name"
        },
        {
            label:"业务经理",
            prop:"pm"
        },
        {
            label:"总营收",
            prop:"yj"
        },
        {
            label:"门店营收",
            prop:"balance"
        },
        {
            label:"账户余额",
            prop:"yue_balance"
        },
        {
            label:"待解冻余额",
            prop:"dai_yue_balance"
        },
        {
            label:"已提现金额",
            prop:"tixian_balance"
        },
        {
            label:"提现待审核金额",
            prop:"dai_tixian_balance"
        },
        {
            label:"提现申请次数",
            prop:"tixian_count"
        },
        // {
        //     label:"总订单",
        //     prop:"orderCount"
        // },
        {
            label:"已支付订单",
            prop:"pay_orderCount"
        },
        // {
        //     label:"当前租赁数",
        //     prop:"curr_lease_item"
        // },
        
        {
            label:"设备投放总量",
            prop:"total_lease_item"
        },
        // {
        //     label:"出租率",
        //     prop:"chuzulv"
        // },
    ]
    const {tableObjet,search}=useBase(getOverView,searchData,false,true)

    return {tableObjet,search,searchData,headers}
}