<template>
    <div>
        <FormComponent title="租赁物品投放" :data="formData" canEdit="true" @submit="createdLeaseItem"></FormComponent>
    </div>


    <div class="flex">
       
        <input type="file" @change="change">
        <el-button @click="piliang">批量投放</el-button>
    </div>

    <div class="tips">
       表格模板
        
    </div>
    <table border="1">
        <tr>
            <td>设备编号
            </td>
            <td>物品编号
            </td>
            <td>
                车架号
            </td>
        </tr>
        <tr>
        <td>
            00002888
        </td>
        <td>
            00002888
        </td>
        <td>
            354857815245
        </td>
    </tr>
    </table>

   
</template>

<script setup>
import {ref} from "vue"
import FormComponent from '@/components/FormComponent.vue';
import {useLeaseItem} from "@/use/leaseitems"
let file=null
const {formData,createdLeaseItem,batchEntry}=useLeaseItem();
const piliang=()=>{
    
    if(file==null){
        alert("请上传文件")
        
    }else{
        if(!formData.store_id.value){
            alert("请先选择门店")
        }else{
            batchEntry(file,formData.store_id.value)
        }
        
    }
}
const change=(e)=>{
    file = e.target.files[0];
      let allowedExtensions = /(\.xls)$/i;
      if (!allowedExtensions.exec(file.name)) {
        file=null
        alert('只允许上传xls格式的文件!');
        e.target.value = '';
        return false;
      }
      
}
</script>

<style lang="scss" scoped>
.flex{

    align-items: center;
    div{
        margin: 10px;
    }
    }
    .tips{
        margin-top: 20px;
        color: #f7baba;

    }
</style>