<template>

    <XIASearchBar :searchData="searchData" @search="search"/>
    <div class="title">
       业务员数据统计
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

    </TableComponent>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useYewuyuan} from "@/use/statistics"

const {tableObjet,search,searchData,headers}=useYewuyuan()
</script>
<style>
</style>