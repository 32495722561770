import { useBase } from "@/use/base"
import { reactive, ref } from "vue"
import { index,tx,cashFlow} from "@/api/jms"
import { ElMessage } from "element-plus"

export function useJms() {
    const searchData = reactive({
        type:{
            label:"名称",
            type:"text",
            
        }
    })
    const headers = [{
        label: "ID",
        prop: "id",
    },
    
    {
        label: "手机号",
        prop: "phone",
    },
    {
        label: "名称",
        prop: "user_name",
    },
    
    {
        label: "余额",
        prop: "balance",
    },
    {
        label: "门店数",
        prop: "stores",
    },
    {
        label: "创建时间",
        prop: "create_time",
    },
]
    const { tableObjet, search } = useBase(index, searchData,false,false)
    return { search, searchData, tableObjet, headers }
}
export function useTx(){


    const data=reactive({
        price:{
            label:"金额",
            type:"text",
            require: true,
            placeholder: "请输入提现的金额",
        },
        name:{
            label:"收款人姓名",
            type:"text",
            require: true,
            placeholder: "请输入收款人姓名",
        },
        account:{
            label:"支付宝账号",
            type:"text",
            require: true,
            placeholder: "请输入收款支付宝账号",
        },
    })
    const submit = (async (params) => {
        const { code, msg } = await tx(params);
       
        if (code == 200) {
            ElMessage.success({ type: "success", message: msg })
        } else {
            ElMessage.error(msg)
        }
    })
    return { data, submit }

}

export function useCahFlow() {
    // const types = ["", "收取押金", "收取租金","押金退回","平台收取分成","提现申请","提现驳回"]
    const searchData = reactive({
        create_time: {
            value: "",
            type: "datePick",
            label: "选择日期"
        },
        type: {
            value: "",
            type: "select",
            label: "变动类型",
            options:
                [
                    {
                        label: "收取佣金",
                        value: 1
                    },
                   
                    {
                        label: "提现申请",
                        value: 2
                    },
                    {
                        label: "提现驳回",
                        value: 3
                    },
                ]
        }
    })
    const headers = [
        {
            label: "ID",
            prop: "id"
        },
        {
            label: "加盟商名称",
            prop: "user_name"
        },
        {
            label: "变动前金额",
            prop: "balance_pre"
        },
        {
            label: "变动金额",
            prop: "price"
        },
        {
            label: "变动后金额",
            prop: "balance_post"
        },
        {
            label: "类型",
            prop: "type"
        },
        {
            label: "变动时间",
            prop: "create_time"
        },
        {
            label: "关联订单",
            prop: "order_num"
        },
        {
            label: "备注",
            prop: "remark"
        }

    ]

    const { tableObjet, search } = useBase(cashFlow, searchData, false, false)
    // adminCashFlow().then(res => data.value = res.data)

    return { searchData, headers, tableObjet, search }
}