<template>

    <div class="storeindex">
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">门店列表</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet" :psize="5">
            <template #state="item">

                <el-switch v-model="item.row.state" disabled
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />


            </template>
            <template #image="scope">
               <img :src="baseUrl+'storage/'+scope.row.image" alt="">
            </template>

            <template #pre_name="scpoe">
                    <el-tag>{{scpoe.row.pre_name }}</el-tag>
            
            </template>
            <template #name="scpoe">
                    <el-tag>{{scpoe.row.name }}</el-tag>
            
            </template>
            <template #caozuo="scope">
                <el-button @click="handleEdit(scope.$index, scope.row)" type="primary" icon="Edit" circle />

                <!-- <el-button @click="storeConfig(item.row)">
                    运营配置
                </el-button> -->
            </template>

            <template #pos="scpoe">
                    <el-button type="info" @click="see(scpoe.row)" round ><el-icon><Position /></el-icon> 查看</el-button>
            
            </template>
            
        </TableComponent>
       
    </div>
    
    <!-- <div v-show="showMap" class="map">
        <XIAMap :lat="lat" :lng="lng"/>

        <el-button @click="showMap=false">关闭</el-button>
    </div> -->
</template>
<script setup>
import {reactive,inject,ref} from "vue"
import XIASearchBar from "@/components/XIA-SearchBar.vue";
import {updateStoreInfo} from "@/api/store"
import TableComponent from '@/components/TableComponent.vue';
import { useStore } from "@/use/store"
import XIAMap from "@/components/XIA-Map.vue";
const {  tableObjet, search, searchData } = useStore();
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const lat=ref(0)
const lng=ref(0)
const showMap=ref(false)

const baseUrl=process.env.VUE_APP_BASE_URL2

const headers = [
    {
        prop: "id",
        label: "门店ID"
    },
    {
        prop: "pre_name",
        label: "门店前缀"
    },
    {
        prop: "name",
        label: "门店名"
    },

    {
        prop: "user_name",
        label: "业务经理"
    },
    
    {
        prop: "jms",
        label: "所属加盟商"
    },
    {
        prop: "address",
        label: "门店地址"
    },
    {
        prop: "phone",
        label: "门店电话"
    },
    {
        prop: "image",
        label: "门店图片"
    },
    
    
    {
        prop: "state",
        label: "运营状态"
    },
    {
        prop: "create_time",
        label: "创建时间"
    },
    {
        prop: "caozuo",
        label: "操作"
    }
]
const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        pre_name: {
            label: "门店前缀",
            
            value: item.pre_name,
            canEdit: true,
            type: "text"
        },
        name: {
            label: "门店名称",
            
            value: item.name,
            canEdit: true,
            type: "text"
        },
        address: {
            label: "门店地址",
            
            value: item.address,
            canEdit: true,
            type: "text"
        },
        phone: {
            label: "门店电话",
            
            value: item.phone,
            canEdit: true,
            type: "text"
        },
        image: {
            label: "大图",
            
            value: "http://127.0.0.1/storage/"+item.image,
            canEdit: true,
            type: "image"
        },
        lat: {
            label: "纬度(-90 - 90)",
            
            value: item.lat,
            canEdit: true,
            type: "text"
        },
        lng: {
            label: "经度(-180 - 180)",
            
            value: item.lng,
            canEdit: true,
            type: "text"
        },
        state: {
            label: "运营状态",
            value: item.state == 1 ? true : false,
            placeholder: "点击开启或者禁用",
            canEdit: true,
            type: "switch"
        }
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateStoreInfo
    drawOptions.formData = formData
    drawOptions.title = "修改门店信息"
    isOpenDrawer.value = true
}
const see=(item)=>{

    lat.value=item.lat
    lng.value=item.lng
    showMap.value=true
}
</script>
<style scoped lang="scss">
img{
    width: 200px;
    height: 100px;
}
.map{
    position: absolute;
    width: 50%;
    top: 0;
    left: 25%;
    margin: auto;
    z-index: 9999;
    text-align: center;
    button{
        background-color: #5677fc;
        color: #fff;
        width: 100px;
        margin-top: 50px;
    }
}
img{
    border-radius: 2px;
}
</style>