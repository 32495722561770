<template>
    <div>
        <div class="title">资金总览</div>
        <div class="flex zonlan">
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    总
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{statisticsData.total_revenue}}</div>
                    <div>
                        总营收
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    门
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{Number(statisticsData.store_revenue).toFixed(2)}}</div>
                    <div>
                        门店总营收
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    平
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{statisticsData.total_revenue-statisticsData.store_revenue}}</div>
                    <div>
                        平台总营收
                    </div>
                </div>
            </div>
        </div>


        <div style="margin-bottom: 20px;">

        </div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">门店资金列表</div>
        
        <TableComponent :headers="headers" :tableObjet="tableObjet">
                <template #total_balance="scoped">
                
                {{+scoped.row.balance+(+scoped.row.freeze_balance)}}
                </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import {useAdminCash} from "@/use/finance"
import XIASearchBar from '@/components/XIA-SearchBar.vue';
const {headers,statisticsData,tableObjet,searchData,search}=useAdminCash();
</script>

<style lang="scss" scoped>
.zonlan {
    flex: 1;
    width: 100%;
    margin-top: 20px;

    .item {
        background-color: #fff;
        height: 100px;
        border-radius: 10px;
        margin: 0 50px;
        border:1px solid rgb(161, 215, 244);
        align-items: center;

        .zs {
            background-color: var(--el-color-primary);
            width: 10px;
            border-radius: 10px;
            margin-right: 50px;
            height: 100%;
        }
        .b{
            color:#999;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border:5px solid var(--el-color-primary);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .c{
            text-align: center;
            color: #999;
            font-size: 13px;
            .price{
                margin-bottom: 10px;
                color: var(--el-color-primary);
                font-size: 25px;
            }
        }
    }

}
</style>