<template>
            <FormComponent title="个人信息" :data="formData" @submit="submit" :canEdit="true"/>
</template>
<script setup>
import {useChangePW} from "@/use/admin"
import FormComponent from '@/components/FormComponent.vue';
const {formData,submit}=useChangePW();


</script>

<style>
</style>