import { useBase } from "@/use/base";
import { reactive, ref } from "vue";
import {
  index,
  getLeaseItemLog,
  getLeaseLog,
  getSwitchCarLog,
  getStartCarLog,
  getYichangCar,
} from "@/api/sysLog";

import { getEventDataHistory } from "@/api/leaseItem";
export function useSysLog() {
  const searchData = reactive({
    type: {
      label: "日志类型",
      type: "select",
      options: [
        {
          label: "登录",
          value: "1",
        },
        {
          label: "支付",
          value: "2",
        },
      ],
    },
  });
  const headers = [
    {
      label: "ID",
      prop: "Id",
    },
    {
      label: "时间",
      prop: "create_time",
    },
    {
      label: "类型",
      prop: "type",
    },
    {
      label: "操作人",
      prop: "user_name",
    },

    {
      label: "备注",
      prop: "remark",
    },
  ];
  const { tableObjet, search } = useBase(index, searchData, false, false);
  return { search, searchData, tableObjet, headers };
}
export function useDeviceEventData() {
  const searchData = reactive({
    device_number: {
      label: "设备编号",
      type: "text",
      value: "",
    },
    time: {
      label: "时间段",
      type: "datePick",
      value: "",
    },
  });
  const headers = [
    {
      label: "时间",
      prop: "createTime",
    },
    {
      label: "类型",
      prop: "eventCode",
    },
    {
      label: "事件名称",
      prop: "eventName",
    },
    {
      label: "eventType",
      prop: "eventType",
    },
  ];
  const { tableObjet, search } = useBase(
    getEventDataHistory,
    searchData,
    false,
    false
  );
  return { search, searchData, tableObjet, headers };
}
export function useLILog() {
  const searchData = reactive({
    lease_item_id: {
      type: "text",
      label: "设备ID",
    },
    device_number: {
      type: "text",
      label: "设备编号",
    },
    device_car_number: {
      type: "text",
      label: "车架号",
    },
    type: {
      type: "select",
      label: "类型",
      value: "",
      options: [
        {
          label: "默认",
          value: 1,
        },
        {
          label: "投放",
          value: 2,
        },
        {
          label: "修改",
          value: 3,
        },
        {
          label: "转移",
          value: 4,
        },
        {
          label: "回收",
          value: 5,
        },
      ],
    },
  });
  const headers = [
    {
      label: "设备ID",
      value: "",
      prop: "lease_item_id",
    },
    {
      label: "关联门店",
      value: "",
      prop: "name",
    },
    {
      label: "类型",
      value: "",
      prop: "type",
    },
    {
      label: "设备号",
      prop: "device_number",
    },
    {
      label: "车架号",
      prop: "device_car_number",
    },
    {
      label: "原设备号",
      prop: "o_device_number",
    },
    {
      label: "原车架号",
      prop: "o_device_car_number",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "时间",
      prop: "create_time",
    },
  ];
  const { tableObjet, search } = useBase(
    getLeaseItemLog,
    searchData,
    false,
    true
  );
  return { search, searchData, tableObjet, headers };
}
export function useLeaseLog() {
  const searchData = reactive({
    lease_item_id: {
      type: "text",
      label: "设备ID",
    },
  });
  const headers = [
    {
      label: "所属门店",
      value: "",
      prop: "name",
    },
    {
      label: "手机号",
      value: "",
      prop: "phone",
    },
    {
      label: "姓名",
      value: "",
      prop: "user_name",
    },
    {
      label: "设备号",
      prop: "device_number",
    },
    {
      label: "车架号",
      prop: "device_car_number",
    },
    {
      label: "金额",
      prop: "price",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "时间",
      prop: "create_time",
    },
  ];
  const { tableObjet, search } = useBase(getLeaseLog, searchData, false, true);
  return { search, searchData, tableObjet, headers };
}

export function useStartLog() {
  const searchData = reactive({
    device_number: {
      type: "text",
      label: "设备编号",
    },
  });
  const headers = [
    {
      label: "所属门店",
      value: "",
      prop: "name",
    },

    {
      label: "设备号",
      prop: "device_number",
    },

    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "时间",
      prop: "create_time",
    },
  ];
  const { tableObjet, search } = useBase(
    getStartCarLog,
    searchData,
    false,
    true
  );
  return { search, searchData, tableObjet, headers };
}
export function useYichang() {
  const searchData = reactive({
    device_number: {
      type: "text",
      label: "设备编号",
    },
    type: {
      type: "select",
      label: "异常类型",
      value: 1,
      options: [
        {
          label: "闲置却启动",
          value: 1,
        },
        {
          label: "闲置,位置超出门店",
          value: 2,
        },
      ],
    },
  });
  const headers = [
    {
      label: "所属门店",
      value: "",
      prop: "name",
    },

    {
      label: "设备号",
      prop: "device_number",
    },
    {
      label: "距离门店(公里)",
      prop: "d",
    },
    {
      label: "异常次数",
      prop: "count",
    },
    {
      label: "lat",
      prop: "lat",
    },
    {
      label: "lng",
      prop: "lng",
    },
    {
      label: "时间",
      prop: "create_time",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];
  const { tableObjet, search } = useBase(
    getYichangCar,
    searchData,
    false,
    true
  );
  return { search, searchData, tableObjet, headers };
}
export function useSwitchCarLog() {
  const searchData = reactive({
    lease_item_id: {
      type: "text",
      label: "设备ID",
    },
  });
  const headers = [
    {
      label: "所属门店",
      value: "",
      prop: "name",
    },
    {
      label: "手机号",
      value: "",
      prop: "phone",
    },
    {
      label: "姓名",
      value: "",
      prop: "user_name",
    },
    {
      label: "新设备号",
      prop: "new_device_number",
    },
    {
      label: "旧设备号",
      prop: "old_device_number",
    },

    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "时间",
      prop: "create_time",
    },
  ];
  const { tableObjet, search } = useBase(
    getSwitchCarLog,
    searchData,
    false,
    false
  );
  return { search, searchData, tableObjet, headers };
}
