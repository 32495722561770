import { shouhouIndex,chuli } from "@/api/shouhou"
import { useBase } from "@/use/base"
import { ref, reactive } from "vue"
export function useShouhou() {
    const searchData = reactive({})

    const headers = [
        
        {
            label: "所属门店",
            prop: "name"
        },
        {
            label: "设备编号",
            prop: "device_number"
        },
        
        {
            label: "手机号",
            prop: "l_phone"
        },
        
        {
            label: "寄件快递号",
            prop: "express_number"
        },
        {
                label:"客户收件地址",
                prop:"address"
        }
        ,
        {
            label:"收件快递号",
            prop:"shou_express_number"
        }
        ,
        {
            label: "故障类型",
            prop: "failureType"
        },
        {
            label: "故障说明",
            prop: "content"
        },
       
        {
            label: "处理说明",
            prop: "huifu"
        },
        {
            label:"更换配件",
            prop:"needSwitchOption"
        },
        {
            label:"处理进度",
            prop:"state"
        },
        {
            label: "提交时间",
            prop: "create_time"
        }
        ,
        {
            label: "操作",
            prop: "caozuo"
        }
    ]
    const { search, tableObjet } = useBase(shouhouIndex, searchData, false, true)


    return { search, searchData, tableObjet, headers }

}