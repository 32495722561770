import { reactive, watch,ref } from "vue";
import {useGetAdminListForOption} from "@/use/admin"
import {useGetStoreList} from "@/use/store"
import {useGetItemModelList} from "@/use/itemModel"
import {add,index,addForFile,kucun} from "@/api/leaseItem"
import { ElMessage } from "element-plus";
import {useBillingRulesOption} from "@/use/billingRule"
import {getItemModelList} from "@/api/itemModel"
import {useBase} from "@/use/base"
import { getBillingRules, getBillingRulesOptions } from "@/api/billingrule";
export function useLeaseItem(){
    const admin_id=ref(0);
    const store_id=ref(0);
    const {admimOption}=useGetAdminListForOption(3);
    const {stores_option,stores}=useGetStoreList(admin_id);
    const itemModelList_option=ref([])
    const {billingRule_options}=useBillingRulesOption(store_id)
    const formData=reactive({
        store_id:{
            label:"选择门店",
            value:"",
            type:"select",
            options:stores_option,
            placeholder:"请选择门店",
            require:true
        },
        item_model:{
            label:"选择物品型号",
            value:"",
            type:"select",
            placeholder:"请选择物品型号",
            options:itemModelList_option,
            require:true
        },
        billing_rule:{
            label:"选择计费规则",
            value:"",
            type:"select",
            placeholder:"请选择计费规则",
            options:billingRule_options,
            require:true
        },
        // item_number:{
        //     label:"物品编号",
        //     value:"",
        //     type:"text",
        //     require:true,
        //     placeholder:"请输入物品编号"
        // },
        device_number:{
            label:"设备号",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入设备号"
        },
        device_car_number:{
            label:"车架号",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入车架号"
        },
        address:{
            label:"门店地址",
            value:"",
            type:"text",
           
            placeholder:"门店地址"
        },
        phone:{
            label:"门店电话",
            value:"",
            type:"text",
           
            placeholder:"门店电话"
        }
    })

    

    watch(()=>formData.store_id.value,(i)=>{
        stores.value.forEach(j=>{
            if(j.id==i){
                formData.address.value=j.address
                formData.phone.value=j.phone
                
            }
        })

        formData.billing_rule.value=""
        
        getBillingRulesOptions(i).then(res=>{
            if(res.code==200){
                formData.billing_rule.options=res.data
            }
        })
        formData.item_model.value=""
        getItemModelList({store_id:i}).then(res=>{
            if(res.code==200){
                formData.item_model.options=res.data.data.map(i=>{
                    return {
                        label:i.model,
                        value:i.id
                    }
                })
            }
        })
    })



    const batchEntry=(file,store_id)=>{
        addForFile({file,store_id}).then(res=>{
            if(res.code==200){
                ElMessage.success(res.msg)
            }else{
                ElMessage.error(res.msg)
            }
        })
    }
    const createdLeaseItem=async (params)=>{
        let data=await add(params);
        if(data.code==200){
            ElMessage.success(data.msg)
        }else{
            ElMessage.error(data.msg)
        }
    }
    return {formData,createdLeaseItem,batchEntry}
}
export function useLeaseItemList(){
    const headers=ref([
        {
            label:"ID",
            prop:"id"
        },
        {
            label:"添加到打印",
            prop:"print"
        },
        {
            label:"前缀",
            prop:"pre_name"
        },
        {
            label:"所属门店",
            prop:"name"
        },
        {
            label:"物品型号",
            prop:"model"
        },
        // {
        //     label:"押金",
        //     prop:"deposit"
        // },
        {
            label:"计费规则",
            prop:"rule_name"
        },
        {
            label:"提现类型",
            prop:"type"
        },
        {
            label:"租金",
            prop:"price"
        },
        // {
        //     label:"15天租金",
        //     prop:"d15_price"
        // },
        
        {
            label:"设备编号",
            prop:"device_number"
        },
        {
            label:"车架号",
            prop:"device_car_number"
        },
        {
            label:"二维吗",
            prop:"qrcode"
        }
        ,
        {
            label:"租赁状态",
            prop:"state"
        },
        
        // {
        //     label:"激活状态",
        //     prop:"active_state"
        // },
        // {
        //     label:"激活码",
        //     prop:"active_code"
        // },
        // {
        //     label:"能否出租/使用",
        //     prop:"lock_state"
        // },
        {
            label:"总租赁天数",
            prop:"lease_count"
        },
        {
            label:"投放时间",
            prop:"create_time"
        },
        {
            label:"操作",
            prop:"caozuo"
        }
    ])
    const searchData=reactive({
        device_number:{
            label:"设备编号",
            type:"text",
            placeholder:"输入设备编号"
        },
        device_car_number:{
            label:"车架号",
            type:"text",
            placeholder:"输入车架号"
        },
        state:{
            label:"租赁状态",
            type:"select",
            placeholder:"选择运营状态",
            options:[
                {
                    label:"闲置",
                    value:0
                },
                {
                    label:"租赁中",
                    value:1
                }
            ]
        }
    })
  
    
    const {tableObjet,search}=useBase(index,searchData,false,true)

    watch(searchData,(i)=>{
        search()

    })
    return {searchData,headers,tableObjet,search}
}


export function useKucun(){
    const headers=ref([
        {
            label:"ID",
            prop:"id"
        },
        {
            label:"原门店前缀",
            prop:"pre_name"
        },
        {
            label:"原门店名称",
            prop:"name"
        },
        {
            label:"设备编号",
            prop:"device_number"
        },
        
        {
            label:"车架号",
            prop:"device_car_number"
        },
        {
            label:"二维吗",
            prop:"qrcode"
        }
        ,
        {
            label:"操作",
            prop:"caozuo"
        }
    ])
    const searchData=reactive({
        device_number:{
            label:"设备号",
            type:"text",
            placeholder:"输入设备编号"
        },
        device_car_number:{
            label:"车架号",
            type:"text",
            placeholder:"输入车架号"
        }
    })

    const {tableObjet,search}=useBase(kucun,searchData)
    watch(searchData,(i)=>{
        search()

    })
    return {searchData,headers,tableObjet,search}

}