<template>
  <div>
    <div class="title">
      {{ title }}
    </div>
    <el-form label-width="120px">

      <el-form-item v-for="(item, key) in data" :key="key" :label="item.label">
        <template v-if="item.type == 'text'">
          <el-input v-model="item.value" :disabled="!canEdit" :class="'xia-' + key" @input="change(item)"
            :placeholder="item.placeholder" />

        </template>
        <template v-else-if="item.type == 'password'">
          <el-input v-model="item.value" :disabled="!canEdit" :class="'xia-' + key" @input="change(item)"
            :placeholder="item.placeholder" type="password" />

        </template>
        <template v-else-if="item.type == 'select'">
          <el-select :disabled="!canEdit" v-model="item.value" clearable :placeholder="item.placeholder"
            @change="change(item)" filterable>
            <el-option v-for="option in item.options" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </template>
        <template v-else-if="item.type == 'switch'">
          <el-switch v-model="item.value" :disabled="!canEdit" />
        </template>

        <template v-else-if="item.type == 'file'">

          <input type="file" @change="changefile" :data-name="item.name" hidden :class="item.name">

          <img :src="item.src" alt="" width="400" height="200" @click="checkImage(item.name)">
        </template>
        <template v-else-if="item.type == 'image'">
          
          <input type="file" @change="changefile" :data-name="key" hidden :class="key">
          
          <img :src="item.src" alt="" width="400" height="200" @click="checkImage(key)">
        </template>
        <template v-else-if="item.type == 'date'">

          <el-date-picker :disabled="!canEdit" v-model="item.value" type="date" :placeholder="item.placeholder" />

        </template>

        <template v-else-if="item.children">



          <template v-for="(i, keyi) in item.children" :key="keyi">

            <div class="flex">
              <template v-for="(j, keyj) in i.children" :key="keyj">

                <div>
                  <template v-if="j.type == 'text'">
                    <el-input :disabled="!canEdit" v-model="j.value" @input="change(j, item)"
                      :placeholder="j.placeholder" />

                    {{j.type}}
                  </template>
                  <template v-else-if="j.type == 'password'">
                    <el-input :disabled="!canEdit" v-model="j.value" @input="change(j, item)"
                      :placeholder="j.placeholder" type="password"/>


                  </template>

                  
                  <template v-else-if="j.type == 'select'">
                    <el-select :disabled="!canEdit" v-model="j.value" clearable :placeholder="j.placeholder"
                      @change="change(j, item)" filterable>
                      <el-option v-for="option in j.options" :key="option.value" :label="option.label"
                        :value="option.value" />
                    </el-select>
                  </template>
                  <template v-else-if="j.type == 'date'">

                    <el-date-picker :disabled="!canEdit" v-model="j.value" type="date" :placeholder="j.placeholder" />

                  </template>
                  <template v-if="j.type == 'button' && canEdit">
                    <el-button @click="click(item, j)">{{ j.value }}</el-button>


                  </template>
                  <el-alert v-if="j.iserror" :title="j.errorText" type="error" show-icon :closable="false" />
                </div>
              </template>
            </div>
          </template>

        </template>




        <el-alert v-if="item.iserror" :title="item.errorText" type="error" show-icon :closable="false" />
      </el-form-item>
      <el-form-item v-if="canEdit">
        <el-button type="primary" @click="onSubmit">{{ isUpdateForm ? "修改" : submitButtonText?submitButtonText:"创建" }}</el-button>
        <el-button @click="clear" v-if="!isUpdateForm">清除</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>

</script>
<script  setup>
const props = defineProps(["data", "title", "width", "canEdit", "isUpdateForm","submitButtonText"])
const emit = defineEmits("submit", "itemclick")
const changefile = async (e) => {
  if (e.target.files.length > 0) {
    props.data[e.target.dataset.name].src = URL.createObjectURL(e.target.files[0]);
    props.data[e.target.dataset.name].value = e.target.files[0];
    console.log(e.target.files[0]);
  }

}
const change = (e, parent) => {

  if (parent && !parent.require) return;
  check(e);

}
const checkImage = (className) => {
  props.data[className].value = "";
  props.data[className].src = "";

  document.querySelector("." + className).click();
}
const click = (item, btn) => {
  emit("itemclick", item, btn)
}
const check = (e) => {
  e.iserror = false;
  if ((e.value == "" || e.value == null || e.value == undefined) && e.require) {
    e.iserror = true;
    e.errorText = "字段不能为空";
  } else {
    if (e.range) {
      if (e.range.length == 1) {
        if (e.value.length != e.range[0]) {
          e.iserror = true;
          e.errorText = "长度应该为" + e.range[0];
        } else {
          e.iserror = false;
        }
      } else if (e.range.length == 2) {
        if (e.value.length >= e.range[0] && e.value.length <= e.range[1]) {
        } else {
          e.iserror = true;
          e.errorText = "长度应该在 " + e.range[0] + " 到 " + e.range[1] + " 内";
        }
      }
    }
    if (e.equal) {
      e.equal.forEach(key => {
        if (props.data[key].value != e.value) {
          e.iserror = true;
          e.errorText = "与字段" + props.data[key].label + "不一致";
        }
      });
    }

  }
}
const clear = () => {
  for (let i in props.data) {
    props.data[i].value = "";
    props.data[i].iserror = false;
    let c = props.data[i].children || [];
    for (let i of c) {
      for (let j of i.children) {
        if (j.type != "button") {
          j.value = "";
          j.iserror = false;
        }
      }
    }
  }
}
const onSubmit = async () => {

  let canSubmit = true;
  let formData = new FormData();
  let params = {};
  let hasFile = false;
  for (let i in props.data) {

    let c = props.data[i].children || [];
    if (props.data[i].require) {
      for (let i of c) {
        for (let j of i.children) {
          if (j.type != "button") {
            check(j);
            if (j.iserror) canSubmit = false;
          }
        }
      }
    }
    check(props.data[i]);

    if (props.data[i].iserror) canSubmit = false;
    if (props.data[i].children) {

      let v = props.data[i].children.map(i => {
        let data = {};
        i.children.forEach((j) => {
          if (j.type !== "button")

            data[j.name] = j.value
        })
        return data
      });
      formData.append(i, JSON.stringify(v));
      params[i] = v;
    } else {
      if (props.data[i].type == "file" && props.data[i].value||props.data[i].type == "image" && props.data[i].value) {
        formData.append(i, props.data[i].value, i);
        hasFile = true;
      } else {
        formData.append(i, props.data[i].value);
        params[i] = props.data[i].value;
      }

    }
  }
  canSubmit && emit('submit', formData);
}
</script>
<style scoped lang="scss">
.el-form {
  width: 1000px;
}

.xia-error {

  color: #f00;

  position: absolute;
  right: 2%;
  text-align: right;
  font-size: 14px;

}

.el-select {
  width: 100%;
}

.flex {
  display: flex;
}
.title{
  margin-bottom: 10px;
}
</style>
