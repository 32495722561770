import { leaseDataAdmin,deviceTJ,getYewuyuanTj } from "@/api/statistics"
import { useBase } from "@/use/base"
import { reactive } from "vue"
export function useLeaseData() {

    const headers = [
        { label: "ID", prop: "id" },
        { label: "门店", prop: "name" },
        { label: "业务员", prop: "user_name" },
       
        {label:"租赁中",prop:"leaseIng"},
        { label: "投放量", prop: "total" },
        {label:"实时出租率",prop:"percent"},
        {label:"本日新增订单",prop:"todayCount"},
        { label: "本月订单数", prop: "now" },
        {label:"总出租率",prop:"all_percent"},
        { label: "门店创建时间", prop: "create_time" },
    ]
    const searchData = reactive({
        create_time: {
            label: "月份",
            value: new Date(),
            placeholder: "时间段",
            type: "month"
        },
        ywy: {
            label: "业务员",
            type: "select",
            placeholder: "选择所属业务员",
            options: [
              {
                label: "赵汝平",
                value: 28,
              },
              {
                label: "罗昌明",
                value: 34,
              },
              {
                label: "梁碧波",
                value: 444,
              },
            ],
            value: "",
          }
    })
    const { tableObjet, search } = useBase(leaseDataAdmin, searchData)

    return { tableObjet, search, headers, searchData }
}

export function useDeviceTJ(){
    const headers = [
        
        { label: "所属门店", prop: "name" },
        { label: "设备编号", prop: "device_number" },
        { label: "租赁次数", prop: "now" },
        { label: "租赁次数", prop: "now" }
    ]
    const searchData = reactive({
        create_time: {
            label: "月份",
            value: null,
            placeholder: "时间段",
            type: "month"
        }
    })
    const { tableObjet, search } = useBase(deviceTJ, searchData,false,true)

    return { tableObjet, search, headers, searchData }
}

export function useYewuyuan(){
    const headers = [
        
        { label: "业务员名称", prop: "user_name" },
        { label: "投放量", prop: "device_count" },
        { label: "订单量", prop: "order_count" }
    ]
    const searchData = reactive({
        create_time: {
            label: "月份",
            value: null,
            placeholder: "时间段",
            type: "month"
        }
    })
    const { tableObjet, search } = useBase(getYewuyuanTj, searchData,false,false)

    return { tableObjet, search, headers, searchData }
}