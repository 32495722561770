<template>

    <div>
        <div class="title">
            广告栏列表
        </div>
        <XIASearchBar @search="search" :searchData="searchData"/>
        <div class="addbanner">
            <el-button @click="handleEdit()" type="primary">
                新增
            </el-button>
        </div>
        <TableComponent :tableObjet="tableObjet" :headers="headers">
                <template #image="scoped">
                <img :src="scoped.row.image" alt="" width="200" height="100">
                </template>
                <template #state="scoped">
                    <el-switch v-model="scoped.row.state"/>

                </template>
                <template #caozuo="scoped">

                    <el-button icon="Edit" type="primary" @click="handleEdit(scoped.row)">编辑</el-button>
                    <el-button icon="delete" type="warning"  @click="removeForId(scoped.row.id)">删除</el-button>
                </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from "@/components/TableComponent.vue";
import { onMounted, ref, reactive, watch, inject } from "vue"
import {useBanner} from "@/use/shop"
import {addBanner,remove,edit} from "@/api/shop"
import XIASearchBar from "@/components/XIA-SearchBar.vue";
import { ElMessage } from "element-plus";
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const {tableObjet,search,searchData,headers} =useBanner()

const handleEdit = (item=null) => {


    const formData = reactive({
        id: {
            label: "id",
            value: item?item.id:"",
            canEdit: false,
            type: "text"
        },
        title: {
            label: "标题",
            placeholder: "请输入标题",
            value: item?item.title:"",
            canEdit: true,
            type: "text"
        },
        url: {
            label: "跳转地址",
            placeholder: "请输入跳转地址",
            value: item?item.url:"",
            canEdit: true,
            type: "text"
        },
        image: {
            label: "图片",
            placeholder: "请选择图片",
            value:  item?item.image:"",
            canEdit: true,
            type: "image"
        },
        state: {
            label: "是否启用",
            
            value: item?item.state:"",
            canEdit: true,
            type: "switch"
        }
        
        
    })
    drawOptions.isEdit = true
    drawOptions.postApi = item?edit:addBanner
    drawOptions.formData = formData
    drawOptions.title = item?"编辑广告栏":"添加广告栏"
    isOpenDrawer.value = true
}
const removeForId=(id)=>{
  remove({id}).then(res=>{
    if(res.code==200){
        ElMessage.success(res.msg)
    }else{
        ElMessage.error(res.msg)
    }
    
  })
}

</script>
<style scoped lang="scss">
.addbanner{
text-align: right;
padding: 20px;
}
</style>