<template>

</template>

<script setup>
import {useRouter,useRoute} from "vue-router"
import {onMounted,onUpdated} from "vue"
const route=useRoute();
const router=useRouter();
onUpdated(()=>{
let path=route.query.path?route.query.path:"/"
console.log(path);
router.replace({path})
})
</script>