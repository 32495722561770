<template>
    

    <XIASearchBar :searchData="searchData" @search="search"/>
    <div class="title">
        售后列表
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #state="item">
            <el-tag :class="'state'+item.row.state">
                {{states[item.row.state]}}
            </el-tag>
        </template>
        <template #shou_express_number="item">
        
            <el-tag>{{item.row.shou_express_number?item.row.shou_express_number:'暂无'}}</el-tag>
        </template>
        <template #express_number="item">
        
        <el-tag>{{item.row.express_number?item.row.express_number:'暂无'}}</el-tag>
    </template>
        <template #needSwitchOption="item">
        
        <el-tag>
            {{item.row.needSwitchOption?'需要':'不需要'}}
        </el-tag>
        </template>
    <template #caozuo="item">
    
        <el-button type="primary" size="small" round @click="handleEdit(null,item.row)" v-if="item.row.state!=5&&item.row.state!=2&&item.row.state!=4">
            处理
        </el-button>
        <el-button type="danger" round size="small"  v-if="item.row.state==1" @click="b(item.row.id)">
            驳回
        </el-button>
    </template>
    
    </TableComponent>
</template>


<script setup>
import {reactive,inject,ref} from "vue"
import {chuli,bohui } from "@/api/shouhou"
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useShouhou} from "@/use/shouhou"
import { ElMessage } from "element-plus";
const {tableObjet,search,searchData,headers}=useShouhou()
const states=['','待处理','待寄件','已寄件','待收件','已完成']
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const b =(id)=>{
    bohui({id}).then(res=>{
        if(res.code==200){
            ElMessage.success(res.msg)
        }else{
            ElMessage.error(res.msg)
        }
    })
}
const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        failureType: {
            label: "故障类型",
            
            value: item.failureType,
            
            type: "text"
        },

        content: {
            label: "故障说明",
            
            value: item.content,
           
            type: "text"
        },
        needSwitchOption: {
            label: "是否需要更换配件",
            
            value: item.needSwitchOption?"需要":"不需要",
           
            type: "text"
        },
        jindu:{
            label: "处理进度",
            
            value: states[item.state],
           
            type: "text"
        },
        huifu:{
            label: "处理意见",
            
            value: item.huifu,
            canEdit: true,
            type: "text"
        }
       
       
        
    })
    if(item.state==1){
        formData.shuoming={
            label:"操作提示",
            value:item.needSwitchOption?"需要更换配件，点击提交，等待用户上传物流信息":"不需要更换配件，输入处理说明，提交即可以完成处理",
            type:"text",
           
        }
    }
    if(item.state==2){
        formData.shuoming={
            label:"操作提示",
            value:"等待用户上传物流信息，无需进行任何操作",
            type:"text",
           
        }
    }
    if(item.state==3){
        formData.shou_express_number={
            label:"快递号",
            value:"",
            type:"text",
            canEdit:true
        }
        formData.shuoming={
            label:"操作提示",
            value:"用户已提交物流信息，配件维修完成，输入寄件单号，完成处理",
            type:"text",
           
        }
       
    }
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = chuli
    drawOptions.formData = formData
    drawOptions.title = "处理售后"
    isOpenDrawer.value = true
}
</script>


<style>
.state5{
    color: #0c0;
}
.state1{
    color: #666;
}

</style>