<template>
    <XIASearchBar @search="search" :searchData="searchData"/>
    <div class="title">订单管理</div>
    <TableComponent :headers="headers" :tableObjet="tableObjet">
        <template #address="scoped">
            {{JSON.parse(scoped.row.address).sheng}}{{JSON.parse(scoped.row.address).shi}}{{JSON.parse(scoped.row.address).xiangxi}}
        </template>
        <template #user_name="scoped">
            {{JSON.parse(scoped.row.address).user_name}}
        </template>
        <template #phone="scoped">
            {{JSON.parse(scoped.row.address).phone}}
        </template>
        <template #number="scoped">
           <div>
            <div v-for="i,index in  JSON.parse(scoped.row.data)" :key="index">
                        X{{i.number}}
           </div>
           </div>
        </template>
        <template #title="scoped">
           <div>
            <div v-for="i,index in  JSON.parse(scoped.row.data)" :key="index">
                        {{i.title}}
           </div>
           </div>
        </template>
        <template #price="scoped">
           <div>
            <div v-for="i,index in  JSON.parse(scoped.row.data)" :key="index">
                        {{i.gPrice}}
           </div>
           </div>
        </template>
        <template #color="scoped">
           <div>
            <div v-for="i,index in  JSON.parse(scoped.row.data)" :key="index">
                        {{i.color}}
           </div>
           </div>
        </template>
        <template #battery="scoped">
           <div>
            <div v-for="i,index in  JSON.parse(scoped.row.data)" :key="index">
                        {{i.battery||i.capacity}}
           </div>
           </div>
        </template>
        <template #state="scoped">
           {{states[scoped.row.state] }}
        </template>
        <template #wuliu_state="scoped">
           {{wuliu_states[scoped.row.wuliu_state] }}
        </template>
        <template #caozuo="scoped">
           
            <el-button v-if="scoped.row.state==2&&scoped.row.wuliu_state==1"  @click="currItem=scoped.row;showFahuoModel=true">发货</el-button>
        </template>

    </TableComponent>
    <el-dialog v-model="showFahuoModel" title="发货"  >
            <div class="flex d">
                <div class="l">物流单号</div>
                <el-input v-model="wuliu_number"></el-input>
            </div>
           
           
            <template #footer>

                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取消</el-button>
                    <el-button type="primary" @click="fahuo()">
                       发货
                    </el-button>
                </span>
                
            </template>
        </el-dialog>
</template>

<script setup>
import XIASearchBar from "@/components/XIA-SearchBar.vue"
import TableComponent from '@/components/TableComponent.vue';
import {useShopOrderList} from "@/use/shop"
import {ref} from "vue"
import { ElMessage } from "element-plus";
const wuliu_number=ref("")
const showFahuoModel=ref(false)
const {search,searchData,headers,tableObjet,queRenFahuo}=useShopOrderList()
const states=["","待付款","已付款待发货","已完成","已取消"]
const wuliu_states=["","未发货","已发货","已收货","已取消"]
const currItem=ref()
const fahuo=()=>{
    if(wuliu_number.value==""){
        ElMessage.error("请输入物流单号")
        return
    }

    queRenFahuo({wuliu_number:wuliu_number.value,order_id:currItem.value.Id})
    showFahuoModel.value=false
}
</script>

<style scoped lang="scss">


</style>