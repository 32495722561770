import {$post} from "@/api/base"
export async function add(params){
   let data= await $post("/leaseItem/add",params);
   return data;
}
export async function index(params){
    let data= await $post("/leaseItem/index",params);
    return data;
 }

 export async function kucun(params){
   let data= await $post("/leaseItem/kucun",params);
   return data;
}

 export async function itemInfo(params){
   let data= await $post("/leaseItem/itemInfo",params);
   return data;
}

export async function getEventDataHistory(params){
   let data= await $post("/leaseItem/getEventDataHistory",params);
   return data;
}
export async function deviceCron(params){
   let data= await $post("/leaseItem/deviceCron",params);
   return data;
}
export async function lastLeaseUser(params){
   let data= await $post("/leaseItem/lastLeaseUser",params);
   return data;
}

export  async function updateLeaseItem(params){
   
   return $post("/leaseItem/updateLeaseItem",params);
}

export  async function getGuiji(params){
   
   return $post("/leaseItem/guiji",params);
}
export async function addForFile(params){
   return $post("/leaseItem/addForFile",params);
}

export async function moveDeviceForId(params){
   return $post("/leaseItem/moveDeviceForId",params);
}
export async function deleteItemForId(params){
   return $post("/leaseItem/deleteItemForId",params);
}



