<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <TableComponent :headers="headers" :tableObjet="tableObjet">
            <template #old_device_number="item">
                    <el-tag style="color: #666;">{{item.row.old_device_number }}</el-tag>
            </template>
            <template #new_device_number="item">
                    <el-tag>{{item.row.new_device_number }}</el-tag>
            </template>
            <template #caozuo="scope">

                <div v-if="(scope.row.state == 1)">

                    <el-button @click="commit(scope.row,true)" type="success" round size="small">通过</el-button>
                    <el-button @click="commit(scope.row,false)" type="danger" round size="small">驳回</el-button>
                </div>
            </template>
            <template #state="scope">
            
            <el-tag :class="'state'+scope.row.state">{{getState(scope.row.state)}}</el-tag>
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useChangeCar } from "@/use/order"
const { search, headers, tableObjet, searchData,commit } = useChangeCar();
const getState=(state)=>{
const states=["","待审核","已通过","已驳回"]
return states[state]
}
</script>

<style lang="scss" scoped>
.state1{
    color: #666;
}
.state2{
    color: #0a2;
}
.state3{
    color: #c00;
}
</style>