import { reactive, ref, watch } from "vue";
import {
  userindex,
  leaseList,
  cashFlow,
  endOrderForId,
  reendOrderForId,
  addBacklistForId,
  idReview,
  reviewId,
  switchDeviceByOrderNumber,
  userPH
} from "@/api/user";
import { useGetStoreList } from "@/use/store";
import { ElMessage } from "element-plus";
import { useBase } from "./base";
import { useMainStore } from "/store/mainStore";
export function useUserIndex() {
  const dataCount = ref(0);
  const currPage = ref(1);
  const searchData = reactive({
    phone: {
      label: "手机号",
      value: "",
      type: "text",
      placeholder: "输入用户手机号",
    },
    user_name: {
      label: "姓名",
      value: "",
      type: "text",
      placeholder: "输入用户姓名",
    },
  });
  const { search, tableObjet } = useBase(userindex, searchData, null, true);
  const tableData = ref([]);
  const headers = ref([
    {
      label: "ID",
      prop: "id",
    },
    { label: "所属门店", prop: "name" },
    {
      label: "手机号",
      prop: "phone",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "用户状态",
      prop: "backlist",
    },
    {
      label: "真实姓名",
      prop: "user_name",
    },
    {
      label: "身份证号",
      prop: "id_num",
    },
    {
      label: "实名认证",
      prop: "v_state",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ]);

  return {
    searchData,
    tableData,
    headers,
    dataCount,
    currPage,
    search,
    tableObjet,
  };
}
export function useLeaseList() {
  const admin_id = ref(0);
  const store = useMainStore();
  watch(
    () => store.curr_select_store_id,
    (id) => {
      if (id) searchData.store_id.value = id;
    }
  );

  const { stores_option } = useGetStoreList(admin_id);
  const searchData = reactive({
    store_id: {
      label: "门店",
      value: "",
      type: "select",
      options: stores_option,
      placeholder: "请选择所属门店",
    },
    phone: {
      label: "手机号",
      value: "",
      type: "text",
      placeholder: "输入用户手机号",
    },
    lease_item_id: {
      label: "物品编号",
      value: "",
      type: "text",
      placeholder: "输入租赁物品编号",
    },
    order_num: {
      label: "订单编号",
      value: "",
      type: "text",
      placeholder: "输入订单编号",
    },
    order_state: {
      label: "订单状态",
      value: 2,
      type: "select",
      options: [
        { label: "未支付", value: 1 },
        { label: "租赁中", value: 2 },
        { label: "超期未还", value: 3 },
        { label: "已完成", value: 4 },
      ],
      placeholder: "请选择订单状态",
    },
  });
  if (store.curr_select_store_id) {
    searchData.store_id.value = store.curr_select_store_id;
  }
  const { search, tableObjet } = useBase(leaseList, searchData);

  const headers = [
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "所属门店",
      prop: "name",
    },
    {
      label: "用户手机号",
      prop: "phone",
    },
    {
      label: "姓名",
      prop: "user_name",
    },
    {
      label: "物品编号",
      prop: "device_number",
    },
    {
      label: "订单号",
      prop: "order_num",
    },
    {
      label: "金额",
      prop: "price",
    },
    {
      label: "订单状态",
      prop: "state",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "到期时间",
      prop: "end_time",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];

  const switchDevice = async (device_number, deviceInfo, time) => {
    if (time) {
      time = time.getTime();
    }
    const res = await switchDeviceByOrderNumber({
      device_number,
      order_num: deviceInfo.order_num,
      time,
    }).catch((error) => {});
    if (res.code == 200) {
      ElMessage.success(res.msg);
      search();
    } else {
      ElMessage.error(res.msg);
    }
  };

  return { searchData, headers, search, tableObjet, switchDevice };
}
export function useCashFlow() {
  const searchData = reactive({
    phone: {
      label: "手机号",
      value: "",
      type: "text",
      placeholder: "输入用户手机号",
    },
    user_name: {
        label: "姓名",
        value: "",
        type: "text",
        placeholder: "输入用户姓名",
      },
  });
  const headers = [
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "用户手机号",
      prop: "phone",
    },
    {
        label: "用户姓名",
        prop: "user_name",
      },
    {
      label: "订单号",
      prop: "order_num",
    },
    {
      label: "金额",
      prop: "price",
    },
    {
      label: "时间",
      prop: "create_time",
    },
    {
      label: "备注",
      prop: "remark",
    },
  ];

  const { search, tableObjet } = useBase(cashFlow, searchData,false,true);

  return { searchData, headers, tableObjet, search };
}
export function usePH() {
  const searchData = reactive({
    phone: {
      label: "手机号",
      value: "",
      type: "text",
      placeholder: "输入用户手机号",
    },
    user_name: {
        label: "姓名",
        value: "",
        type: "text",
        placeholder: "输入用户姓名",
      },
  });
  const headers = [
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "用户手机号",
      prop: "phone",
    },
    {
        label: "用户姓名",
        prop: "user_name",
      },
    {
      label: "租赁次数",
      prop: "count",
    },
    {
      label: "金额",
      prop: "price",
    }
  ];

  const { search, tableObjet } = useBase(userPH, searchData,false,true);

  return { searchData, headers, tableObjet, search };
}
export function useEndOrder() {
  const endOrder = (item) => {
    endOrderForId(item.id)
      .then((res) => {
        if (res.code == 200) {
          ElMessage.success(res.msg);
          item.state = 4;
        } else {
          ElMessage.error(res.msg);
        }
      })
      .catch(() => {
        ElMessage.error("结束订单失败，请重试！");
      });
  };
  const reendOrder = (item) => {
    reendOrderForId(item.id)
      .then((res) => {
        if (res.code == 200) {
          ElMessage.success(res.msg);
          item.state = 2;
        } else {
          ElMessage.error(res.msg);
        }
      })
      .catch(() => {
        ElMessage.error("恢复订单失败，请重试！");
      });
  };
  return { endOrder,reendOrder };
}
export function useAddBackList() {
  const addBacklist = (item) => {
    addBacklistForId(item.id)
      .then((res) => {
        if (res.code == 200) {
          ElMessage.success(res.msg);
          item.backlist = !item.backlist;
        } else {
          ElMessage.error(res.msg);
        }
      })
      .catch(() => {
        ElMessage.error("结束订单失败，请重试！");
      });
  };
  return { addBacklist };
}
export function useIdReview() {
  const searchData = reactive({
    phone: {
      label: "手机号",
      value: "",
      type: "text",
    },
    user_name: {
      label: "姓名",
      value: "",
      type: "text",
    },
    v_state: {
      label: "审核状态",
      value: 1,
      type: "select",
      options: [
        { label: "待审核", value: 1 },
        { label: "审核通过", value: 2 },
      ],
    },
  });
  const { tableObjet, search } = useBase(idReview, searchData, false, true);

  const headers = [
    { label: "手机号", prop: "phone" },
    { label: "姓名", prop: "user_name" },
    { label: "身份证", prop: "id_num" },
    { label: "所属门店", prop: "name" },
    { label: "操作", prop: "caozuo" },
  ];
  const review = (item, state = 2) => {
    reviewId({ id: item.id, state }).then((res) => {
      if (res.code == 200) {
        ElMessage.success(res.msg);
        item.v_state = 2;
      } else {
        ElMessage.error(res.msg);
      }
    });
  };
  return { searchData, headers, tableObjet, search, review };
}
