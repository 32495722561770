<template>
  <div>

    <div class="title">
      菜单列表
    </div>
    
    <div class="flex">
      <div class="f1">
        <div v-for="m in menuList" :key="m.id">
          <div class="parent">
           
            <span @click="m.showChildren = !m.showChildren" class="o">
             
              <svg class="jiantou" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" :class="m.showChildren?'t90':''"><path fill="currentColor" d="M384 192v640l384-320.064z"></path></svg>
              {{m.label}}


            </span>

            <span @click="switchState(m)" style="margin-left: 20px;">
                
                <svg v-if="!m.show" t="1680156743208" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2781" width="20px" height="20px"><path d="M520.677966 906.365348c-254.553672 0-462.824859-146.561205-462.824859-327.834275s208.271186-327.834275 462.824859-327.834275 462.824859 146.561205 462.824859 327.834275-208.271186 327.834275-462.824859 327.834275z m0-578.531073c-212.12806 0-385.687382 111.849341-385.687382 250.696798s173.559322 250.696798 385.687382 250.696799 385.687382-111.849341 385.687382-250.696799c0-136.919021-173.559322-250.696798-385.687382-250.696798z" fill="#2c2c2c" p-id="2782"></path><path d="M520.677966 752.090395c-96.421846 0-173.559322-77.137476-173.559322-173.559322s77.137476-173.559322 173.559322-173.559322 173.559322 77.137476 173.559322 173.559322-77.137476 173.559322-173.559322 173.559322z m0-269.981167c-53.996234 0-96.421846 42.425612-96.421845 96.421845s42.425612 96.421846 96.421845 96.421846 96.421846-42.425612 96.421846-96.421846c0-52.067797-42.425612-96.421846-96.421846-96.421845z" fill="#2c2c2c" p-id="2783"></path></svg>
                <svg v-else t="1680156743208" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2781" width="20px" height="20px"><path d="M520.677966 906.365348c-254.553672 0-462.824859-146.561205-462.824859-327.834275s208.271186-327.834275 462.824859-327.834275 462.824859 146.561205 462.824859 327.834275-208.271186 327.834275-462.824859 327.834275z m0-578.531073c-212.12806 0-385.687382 111.849341-385.687382 250.696798s173.559322 250.696798 385.687382 250.696799 385.687382-111.849341 385.687382-250.696799c0-136.919021-173.559322-250.696798-385.687382-250.696798z" fill="#388CEB" p-id="2782"></path><path d="M520.677966 752.090395c-96.421846 0-173.559322-77.137476-173.559322-173.559322s77.137476-173.559322 173.559322-173.559322 173.559322 77.137476 173.559322 173.559322-77.137476 173.559322-173.559322 173.559322z m0-269.981167c-53.996234 0-96.421846 42.425612-96.421845 96.421845s42.425612 96.421846 96.421845 96.421846 96.421846-42.425612 96.421846-96.421846c0-52.067797-42.425612-96.421846-96.421846-96.421845z" fill="#E78E00" p-id="2783"></path></svg>
              </span>

          </div>

          <div class="children" v-if="m.showChildren">
            <div v-for="(mc, key) in m.children" :key="mc.id" :class="mc.show ? 'show' : 'hide'">

              <span @click="switchState(mc)">
                
                <svg v-if="!mc.show" t="1680156743208" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2781" width="20px" height="20px"><path d="M520.677966 906.365348c-254.553672 0-462.824859-146.561205-462.824859-327.834275s208.271186-327.834275 462.824859-327.834275 462.824859 146.561205 462.824859 327.834275-208.271186 327.834275-462.824859 327.834275z m0-578.531073c-212.12806 0-385.687382 111.849341-385.687382 250.696798s173.559322 250.696798 385.687382 250.696799 385.687382-111.849341 385.687382-250.696799c0-136.919021-173.559322-250.696798-385.687382-250.696798z" fill="#2c2c2c" p-id="2782"></path><path d="M520.677966 752.090395c-96.421846 0-173.559322-77.137476-173.559322-173.559322s77.137476-173.559322 173.559322-173.559322 173.559322 77.137476 173.559322 173.559322-77.137476 173.559322-173.559322 173.559322z m0-269.981167c-53.996234 0-96.421846 42.425612-96.421845 96.421845s42.425612 96.421846 96.421845 96.421846 96.421846-42.425612 96.421846-96.421846c0-52.067797-42.425612-96.421846-96.421846-96.421845z" fill="#2c2c2c" p-id="2783"></path></svg>
                <svg v-else t="1680156743208" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2781" width="20px" height="20px"><path d="M520.677966 906.365348c-254.553672 0-462.824859-146.561205-462.824859-327.834275s208.271186-327.834275 462.824859-327.834275 462.824859 146.561205 462.824859 327.834275-208.271186 327.834275-462.824859 327.834275z m0-578.531073c-212.12806 0-385.687382 111.849341-385.687382 250.696798s173.559322 250.696798 385.687382 250.696799 385.687382-111.849341 385.687382-250.696799c0-136.919021-173.559322-250.696798-385.687382-250.696798z" fill="#388CEB" p-id="2782"></path><path d="M520.677966 752.090395c-96.421846 0-173.559322-77.137476-173.559322-173.559322s77.137476-173.559322 173.559322-173.559322 173.559322 77.137476 173.559322 173.559322-77.137476 173.559322-173.559322 173.559322z m0-269.981167c-53.996234 0-96.421846 42.425612-96.421845 96.421845s42.425612 96.421846 96.421845 96.421846 96.421846-42.425612 96.421846-96.421846c0-52.067797-42.425612-96.421846-96.421846-96.421845z" fill="#E78E00" p-id="2783"></path></svg>
              </span>
              <span>{{ mc.label }} </span>
              <span @click="up(m, key)" class="up" v-if="key!==0">↑</span>   
              <span @click="down(m, key)" class="down" v-if="key!==m.children.length-1">↓</span>
             
              
            </div>
          </div>
        </div>
      </div>


      <div style="margin-top: 20px; display: flex;" >
        <!-- <el-button type="success">保存修改</el-button>
        <el-button type="info">全部展开</el-button>
        <el-button type="info">全部收起</el-button> -->
      </div>


    </div>
  </div>
</template>

<script setup>
import { getMenuList,change,changeshow } from "@/api/menu.js"

import { ref, reactive, onMounted } from "vue"
import { CLOSING } from "ws";

const customNodeClass = (data, node) => {
  if (data.order) {
    return 'is-penultimate'
  }
  return null
}
const defaultProps = {
  children: 'children',
  label: 'label',
  class: customNodeClass
}
let menuList = ref([]);

onMounted(async () => {
  let isShowAll = true;

  const { data } = await getMenuList(isShowAll);
  getMenuList(isShowAll).then()
  menuList.value = data.map(i => { let children = []; if (i.menuItems) { children = i.menuItems.map(j => { return { label: j.name, id: j.id, show: j.show, order: j.order } }) } return { label: i.name, children, id: i.id, show: i.show, order: i.order } });

})
const switchState = (mc) => {
  
  changeshow(mc.id).then(res=>{
    mc.show = !mc.show
  })
}

const up = (m, key) => {
  // console.log(m);
  

  
  change(m.children[key].id,m.children[key-1].id).then(res=>{
    [m.children[key], m.children[key - 1]] = [m.children[key - 1], m.children[key]]
  })
}
const down = (m, key) => {
  
  change(m.children[key].id,m.children[key+1].id).then(res=>{
    [m.children[key], m.children[key + 1]] = [m.children[key + 1], m.children[key]]
  })
  // console.log(m);
  
}
</script>
<style  lang="scss">
.f1 {
  margin-top: 20px;
  flex: 1;
}

.parent {
  margin-left: 20px;
  height: 30px;
  line-height: 30px;
  
  padding: 0 5px;
  display: flex;
  align-items: center;

  

  .o {
    cursor: pointer;
  }
}
.t90{
  transform: rotateZ(90deg);
}
.jiantou{
  color: #0a9;
  transition: all 300ms ease-in;
}

.children div {
  cursor: pointer;
  margin-left: 50px;
  height: 30px;
  line-height: 30px;

  padding: 0 5px;
 

  

  span{
    display: inline-block;
    margin: 0 10px;

  }


}

.show {
  color: #39bee6 !important;
}

.hide {
  
  color: #999 !important;
}
.up{
  font-weight: bold;
  color: #5677fc;

}
.down{
  font-weight: bold;
  color: #fc5677;
}
</style>