<template>
    <Home v-show="currIndex == 0"/>

    <Device v-show="currIndex == 1"/>

    <div v-show="currIndex == 2">



    </div>
    <div v-show="currIndex == 3">



    </div>
    <div class="tabbar">
        <div v-for="(i, key) in tabs" :key="i" :class="key == currIndex ? 'select' : ''" @click="switchTabbar(key)">
            {{ i }}
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref, watch } from "vue";

import Home from "./home.vue"
import Device from "./device.vue"
const tabs = ["首页", "物品控制", "设备录入", "个人中心"]
const currIndex = ref(0);

const switchTabbar = (key) => {
    currIndex.value = key
}
</script>


<style scoped lang="scss">


.tabbar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    display: flex;
    height: 5vh;
    background-color: #f7f7f7;
    align-items: center;
    border-top: 1px solid #eee;

    div {
        flex: 1;
        text-align: center;
        color: #666;
    }
    .select {
    color: #5677fc;
    font-weight: bold;
}
}


</style>