import {list} from '@/api/banner'
import {reactive,ref} from "vue"
import {useBase} from "@/use/base"
export function useBanner(){
    const searchData=reactive({
        title:{
            type:"text",
            label:"标题"
        }
    })
    const headers=[
        {
            label:"id",
            prop:"id"
        },
        {
            label:"标题",
            prop:"title"
        },
        {
            label:"图片",
            prop:"image"
        },
        {
            label:"跳转地址",
            prop:"url"
        },
        {
            label:"启用状态",
            prop:"state"
        },
        {
            label:"操作",
            prop:"caozuo"
        }
    ]
    const {tableObjet,search} =useBase(list,searchData)

    return {search,searchData,tableObjet,headers}


}