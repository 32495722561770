import {$post} from "@/api/base"

export function index(params){
    return $post("/syslog/index",params)
}

export function getLeaseItemLog(params){
    return $post("/syslog/getLeaseItemLog",params)
}
export function getLeaseLog(params){
    return $post("/syslog/getLeaseLog",params)
}
export function getSwitchCarLog(params){
    return $post("/syslog/getSwitchCarLog",params)
}
export function getStartCarLog(params){
    return $post("/syslog/getStartCarLog",params)
}
export function getYichangCar(params){
    return $post("/syslog/getYichangCar",params)
}
