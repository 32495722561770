<template>


  <div class="c">
    

        <div id="qrcode">
                <div style="text-align: center; color: #000;font-weight: bold;">
                    
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>


                    <vue-qr :logoScale="0.2"  :margin="5" logoSrc="favicon.ico"
                        :text="'https://jimove.jimoveebikes.com/' + device_number" :size="180"></vue-qr>

                    <div style="margin-top: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp; {{ device_number }}
                    </div>
                </div>

          </div>
    
        <el-input type="text" v-model="device_number" placeholder="输入模块编号" style="width:150px;margin-bottom: 20px;"/>  <el-button @click="dayin">打印</el-button>
   
   
  </div>
</template>

<script setup>
import { ref } from "vue"
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import print from "print-js"
const device_number = ref("")
import jsPDF from 'jspdf';
const dayin = () => {

// const LODOP = getLodop()

// LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById('qrcode').innerHTML)
// LODOP.ADD_PRINT_HTM(0,0,'100%','100%',document.getElementById('qrcode').innerHTML)
// LODOP.SET_PRINT_PAGESIZE(1, 650, 710, "qrcode");
//LODOP.PREVIEW();
// LODOP.PRINT();
// LODOP.PRINT();
// return
print({
    printable: 'qrcode',
    type: "html",
    style: "text-align: center; color: #f00;font-weight: bold;border: 1px solid #e00;",

})
return
// print the html string

// 获取要打印的节点
const node = document.getElementById('qrcode');

// 创建一个新的 jsPDF 实例
const doc = new jsPDF();

// 将节点的 HTML 内容添加到 PDF 中
doc.html(node, {
    callback: function () {
        // 调用浏览器的打印对话框
        window.print();
    }
});
//window.print()
// print the dom element
//const  content= document.getElementById('qrcode')
//callPrinter(`<img src="https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data=https://jimove.jimoveebikes.com/080265295"/>22`)
}
</script>

<style scoped lang="scss">

.c{
    display: flex;
    flex-direction: column;
    text-align: center;
    
    align-items: center;
}
</style>