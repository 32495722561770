<template>
    <XIASearchBar :searchData="searchData" @search="search"/>
    <div class="title">
       租赁日志
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #device_number="item" >
            <a :href="'#/leaseitem/detail?device_number=' + item.row.device_number" target="_blank">
                    <el-button  type="primary" icon="Memo">{{item.row.device_number}}</el-button>
                </a>
        </template>

        <template #caozuo="item" >
           
                    <el-button  type="danger" icon="Delete">删除记录</el-button>
              
        </template>
    </TableComponent>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useYichang} from "@/use/sysLog"

const {tableObjet,search,searchData,headers}=useYichang()
</script>