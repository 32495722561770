
import { reactive, ref } from "vue";
import { useRule } from "@/use/rule"
import { addUser, adminOptionList,changePW } from "@/api/admin"
import { ElMessage } from "element-plus";

import {useMainStore} from "../../store/mainStore"
/**
 * 
 * @param {number} adminType 商户类型 0忽略 2加盟商 3门店  
 * @returns {Array} 
 */
export function useGetAdminListForOption(adminType = 0) {
    const admimOption = ref([]);
    const params = { adminType }
    adminOptionList(params).then(data => {
        admimOption.value = data.data.map(i => {
            return {
                label: i.name,
                value: i.id
            }
        })
    })
    return { admimOption }
}
export function useChangePW() {

    const store=useMainStore()

    const formData = reactive({
        pass_word:{
            type: 'password',
            label:"更改密码",
            //require: true,
            placeholder: "请输入新密码,不修改不需要输入",
            value: "",
        },
        pass_word2:{
            type: 'password',
            label:"确认新密码",
            //require: true,
            placeholder: "请再次输入新密码,不修改不需要输入",
            value: "",
        }
        
        

    })

    if(store.user_type==1){
        formData['pass']={
            type: 'password',
            label:"更改操作密码",
            //require: true,
            placeholder: "请再次输入新密码,不修改不需要输入",
            value: "",
        },
        formData['pass2']={
            type: 'password',
            label:"确认操作密码",
            //require: true,
            placeholder: "请再次输入确认操作密码,不修改不需要输入",
            value: "",
        }
    }
    const submit = async (parmas) => {
        const { code, msg } = await changePW(parmas);
        if (code == 200) {
            ElMessage.success(msg)
        } else {
            ElMessage.error(msg)
        }
    }
    return { formData, submit }
}
export function useAddAdmin() {
    const { admimOption } = useGetAdminListForOption(3);
    const { ruleList_option } = useRule();
    const formData = reactive({
        user_name: {
            type: 'text',
            label: "账户名",
            require: true,
            placeholder: "请输入账户名",
            value: "",
        },
        parent: {
            type: 'select',
            label: "绑定门店",
            options: admimOption,
            value: "",
            placeholder: "请选择门店，业务经理和平台客服不需要选择"
        },
        rule: {
            type: 'select',
            label: "账户类型",
            require: true,
            options: ruleList_option,
            value: "",
            placeholder: "请选择账户类型"
        },
        phone: {
            type: 'text',
            label: "手机号",
            require: true,
            value: "",
            placeholder: "请输入手机号"
        },
        pass_word: {
            type: 'password',
            label: "密码",
            require: true,
            value: "",
            placeholder: "请输入密码"
        },
        pass_word2: {
            type: 'password',
            label: "确认密码",
            require: true,
            value: "",
            placeholder: "请确认密码",
            equal: ['pass_word']

        }
    })

    const submit = async (parmas) => {
        const { code, msg } = await addUser(parmas);
        if (code == 200) {
            ElMessage.success(msg)
        } else {
            ElMessage.error(msg)
        }
    }
    return { formData, submit }
}