
import { getItemModelList } from "@/api/itemModel"
import { ElMessage } from "element-plus";
import { ref, watch, reactive, watchEffect } from "vue"
import { useGetStoreList } from "@/use/store"
import { addItemModel } from "@/api/itemModel"
import { useBase } from "./base";
export function useGetItemModelList(store_id) {

    
  

    const remvoe = (i) => {
        ElMessage.warning("暂未实现!");
    }

    const searchData = reactive(
        {
        }
    )

    const {tableObjet,search}=useBase(getItemModelList,searchData,false,true)


   
    return { remvoe, searchData,tableObjet,search}
}


export function useAddItemModel() {
    
    const { stores_option } = useGetStoreList();

    const data = reactive({
        store_id: {
            type: "select",
            label: "所属门店",
            value: "",
            require: true,
            placeholder: "请选择门店",
            options: stores_option
        },
        model: {
            type: "text",
            label: "物品型号",
            value: "",
            require: true,
            placeholder: "请输入物品型号"
        },
        type: {
            type: "select",
            label: "物品类目",
            value: "",
            require: true,
            placeholder: "请选择物品类目",
            options: [
                {
                    label: "电动车",
                    value: 1
                },
                {
                    label: "电池",
                    value: 2
                }
            ]
        },
        deposit: {
            placeholder: "请输入物品的押金",
            type: "text",
            label: "押金",
            value: "",
            require: true
        },
        v: {
            placeholder: "请输入电池电压",
            type: "text",
            label: "电池电压",
            value: "",
            require: true
        },
        a: {
            placeholder: "请输入电池电流",
            type: "text",
            label: "电池电流",
            value: "",
            require: true
        },
        mah: {
            placeholder: "请输入电池容量",
            type: "text",
            label: "电池容量",
            value: "",
            require: true
        },
        description: {
            placeholder: "请输入物品的租赁说明",
            type: "text",
            label: "说明",
            value: "",
            require: true
        },
        feature: {
            placeholder: "请输入物品的特点，简介",
            type: "text",
            label: "特点",
            value: "",
            require: true
        },
        big_img:{
             placeholder:"请选择物品的大图",
            type:"file",
            label:"物品大图",
            value:"",
            name:"big_img",
            require:true
        },
        min_img:{
             placeholder:"请选择物品的略缩图",
            type:"file",
            label:"物品略缩图",
            value:"",
            name:"min_img",
            require:true
        }
    })
    const submit = (async (params) => {
        const { code, msg } = await addItemModel(params);
        if (code == 200) {
            ElMessage.success({ type: "success", message: msg })
        } else {
            ElMessage.error(msg)
        }
    })
    return { data, submit }
}