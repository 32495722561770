import axios from "axios"
import {$post} from "@/api/base"
export async function getStoreList(params){
    
   

    return $post("/store/index",params);

}


export async function addStore(params){
    return $post("/store/add",params);

}
export async function getStoreConfig(store_id){
    
    let params=new FormData();
    params.append("store_id",store_id);
    return $post("/store/config",params);

}
export async function updateStoreConfig(params){
    
    return $post("/store/update",params);

}
export async function updateStoreInfo(params){
    
    return $post("/store/updateStoreInfo",params);

}
export async function getPMList(){
    
    return $post("/store/getPMList");

}
export async function getJMSList(){
    
    return $post("/store/getJMSList");

}



export async function getStoreInfo(id){
    return $post("/store/getStoreInfo",{store_id:id});

}
export async function addfzd(id,lat,lng,name){
    return $post("/store/addfzd",{store_id:id,lat,lng,name});
}
