<template>
    <div>
        <FormComponent @submit="submit" title="运营配置" canEdit="true" :data="data" ></FormComponent>
    </div>
</template>

<script setup>
import FormComponent from '@/components/FormComponent.vue';
import { reactive, ref, watch, watchEffect, onMounted } from 'vue';
import { useGetStoreList } from "@/use/store"

import {addfzd} from "@/api/store"
import { ElMessage } from 'element-plus';
const { stores_option } = useGetStoreList();

const data = reactive({

    store_id: {
        label: "门店",
        type: "select",
        value: null,
        require: true,
        placeholder: "请选择门店",
        options: stores_option
    },
    lat: {
        label: "纬度(-90 - 90)",
        type: "text",
        require: true,
        value: ""
    },
    lng: {
        label: "经度(-180 - 180)",
        type: "text",
        require: true,
        value: ""
    },
    name:{
        label: "分组点地址",
        type: "text",
        require: true,
        value: ""
    }
})

const submit=()=>{
    addfzd(data.store_id.value,data.lat.value,data.lng.value,data.name.value).then(res=>{
            ElMessage.info(res.msg)
    })
}
</script>