<template>

    <div class="search">
        <div class="item" v-for="(item,key) in searchData" :key="item.label">
            <span>{{ item.label }}</span>
            <template v-if="item.type == 'text'">
                <el-input type="text" v-model="item.value" :placeholder="item.placeholder"/>
            </template>
            <template v-if="item.type == 'number'">
                <el-input type="number" v-model="item.value" :placeholder="item.placeholder"/>
            </template>
            
            <template v-if="item.type == 'select'">
                
                <el-select v-model="item.value" clearable :placeholder="item.placeholder">
                   
                
                    <el-option v-for="o in item.options" :key="o.value" :label="o.label" :value="o.value" />
                
            </el-select>
            </template>
            

        </div>
       
        <el-button type="primary" icon="Search" @click="search">查询</el-button>

    </div>

</template>

<script setup>
import { reactive, ref } from 'vue';

const props = defineProps(['searchData']);
const emit=defineEmits(['search','change','update:searchData'])
const searchData = props.searchData;
const search=()=>{
    emit("search")
}
</script>
<style scoped lang="scss">
.search {
    display: flex;
    border: 1px solid blueviolet;
    background-color: var(--base-color);
    padding: 20px;
    border-radius: 5px;
    align-items: center;
    flex-wrap: wrap;

    .item {
        display: flex;
        width: 25%;
        align-items: center;
        margin-bottom: 10px;
        span {
            font-size: 20px;
            color: #666;
            margin-right: 25px;
            width: 100px;
            text-align: left;
        }

        .el-input {
            margin-right: 20px;
            width: 200px;
        }
    }

    .el-button {
        margin-left: 20px;
        width: 100px;
        
    }
}
</style>