<template>
    <FormComponent @submit="submit" title="商品录入" :data="fromData" canEdit="true"></FormComponent>
</template>

<script setup>
 import FormComponent from '@/components/FormComponent.vue'
import {useShop} from "@/use/shop"

const {fromData,submit}=useShop()
</script>

<style scoped lang="scss">


</style>