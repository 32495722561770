<template>
  <div>
   {{ fzd }}
  </div>
    <div id="allmap">

    </div>
   
</template>
<script setup>

import { ref,watch, onMounted, watchEffect,defineProps } from 'vue'


import { geocoding } from "@/api/map";

const props=defineProps(["lat","lng","list",'fzd'])
const point = ref(0);
let map = null;

const createMap = () => {
  point.value = new BMapGL.Point(props.lng,props.lat);
  // 百度地图API功能
  map = new BMapGL.Map("allmap");    // 创建Map实例
  map.centerAndZoom(point.value, 10);
  map.enableScrollWheelZoom(true);
}
const addLocationControl = () => {
  // 创建定位控件
  var locationControl = new BMapGL.LocationControl({
    // 控件的停靠位置（可选，默认左上角）
    anchor: BMAP_ANCHOR_TOP_RIGHT,
    // 控件基于停靠位置的偏移量（可选）
    offset: new BMapGL.Size(20, 20)
  });
  // 将控件添加到地图上
  map.addControl(locationControl);

  // 添加定位事件
  locationControl.addEventListener("locationSuccess", function (e) {
    var address = '';
    address += e.addressComponent.province;
    address += e.addressComponent.city;
    address += e.addressComponent.district;
    address += e.addressComponent.street;
    address += e.addressComponent.streetNumber;
    alert("当前定位地址为：" + address);
  });

}
const addOverlay = (o) => {
  map.addOverlay(o);
}
const createStoreMarker = () => {
  // 创建小车图标
  //var myIcon = new BMapGL.Icon("/vue/machine.png", new BMapGL.Size(50, 50));

  var myIcon = new BMapGL.Icon("/vue/md.png", new BMapGL.Size(50, 50));
  // 创建Marker标注，使用小车图标
  var pt = point.value;
  var marker = new BMapGL.Marker(pt, {
    icon: myIcon
  });



  addOverlay(marker);

  props.fzd.forEach(i=>{
      var myIcon = new BMapGL.Icon("/vue/md.png", new BMapGL.Size(50, 50));
    // 创建Marker标注，使用小车图标
    var pt = new BMapGL.Point(i.lng,i.lat);;
    var marker = new BMapGL.Marker(pt, {
      icon: myIcon
    });



    addOverlay(marker);
  })
}

const getDistance=(lat1, lng1, lat2, lng2)=> {
    lat1 = lat1 || 0;
    lng1 = lng1 || 0;
    lat2 = lat2 || 0;
    lng2 = lng2 || 0;

    var rad1 = lat1 * Math.PI / 180.0;
    var rad2 = lat2 * Math.PI / 180.0;
    var a = rad1 - rad2;
    var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
    var r = 6378137;
    var distance = r * 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2)));

    return distance;
}

const createCarMarker = (i) => {
  // 创建小车图标
  var myIcon = new BMapGL.Icon("/vue/machine.png", new BMapGL.Size(50, 50));

 // var myIcon = new BMapGL.Icon("/vue/md.png", new BMapGL.Size(50, 50));
  // 创建Marker标注，使用小车图标
  var pt = new BMapGL.Point(i.lng,i.lat);;
  
  let isOut=false
  let d=getDistance(point.value.lat,point.value.lng,i.lat,i.lng)
  
  if(d>500&&i.state==0){

    isOut=true
   
  }

  if(isOut){
    props.fzd.forEach(j=>{
      let d2=getDistance(point.value.lat,point.value.lng,j.lat,j.lng)
      if(d2<500){
          d=d2
          isOut=false
      }

    })
  }
  if(isOut){
    myIcon = new BMapGL.Icon("/vue/jg.png", new BMapGL.Size(50, 50));
  }
  d=Number(d/1000).toFixed(2)
  var marker = new BMapGL.Marker(pt, {
    icon: myIcon
  });

  // 创建信息窗口
  var opts = {
    width: 300,
    height: 200,
    title: "设备信息"
  };

  var myGeo = new BMapGL.Geocoder();

  myGeo.getLocation(new BMapGL.Point(i.lng, i.lat), function (result) {
    marker.addEventListener('click', function () {

  var infoWindow = new BMapGL.InfoWindow(`
  <b>所属门店:</b> : ${i.name} <br>
  <b>业务经理:</b> : ${i.pm} <br>
  <b>设备编号:</b> : ${i.device_number}   <a href="#/leaseitem/detail?device_number=${i.device_number}" target="_blank">查看详情</a> <br> 

  <b>租赁状态:</b>:${i.state?"出租":"闲置"} <br>

  <b>距离门店:</b> : ${d} 公里 <br>
  <b>设备定位:</b> : ${result.address} <br>
  <b>定位时间:</b> : ${i.curr_time} <br>
  
  <b>latC:</b> : ${i.lat} <br>
  <b>lonC:</b> : ${i.lng} <br>
  
  `, opts);
  map.openInfoWindow(infoWindow, pt); // 开启信息窗口
  });
  })
  //点标记添加点击事件
  
  // 将标注添加到地图
  addOverlay(marker);
}
const createCircl = () => {
  // 绘制圆
  var circle = new BMapGL.Circle(point.value, 500, {
    strokeColor: 'blue',
    strokeWeight: 2,
    strokeOpacity: 0.5
  });
  addOverlay(circle);
}

const cityControll = () => {
  // 创建城市选择控件
  var cityControl = new BMapGL.CityListControl({
    // 控件的停靠位置（可选，默认左上角）
    anchor: BMAP_ANCHOR_TOP_LEFT,
    // 控件基于停靠位置的偏移量（可选）
    offset: new BMapGL.Size(10, 5)
  });
  // 将控件添加到地图上
  map.addControl(cityControl);
}
/**
 * 点击选择位置，并设置为地图中心
 */
const checkPoint = () => {

  setTimeout(() => {
    map.addEventListener('click', (e) => {
      // alert(e)

      point.value.lng = e.latlng.lng;
      point.value.lat = e.latlng.lat;
      map.setCenter(point.value); // 设置地图中心点
      map.clearOverlays();
      createCarMarker();
      createCircl();
    });
  }, 2000);
}
const mapInit = () => {
  createMap();
  createStoreMarker();
  createCircl();
  cityControll();
  //checkPoint();
  addLocationControl();

  watchEffect(() => {

    map.clearOverlays();
    createStoreMarker();
    createCircl();
  })
}


watch(()=>props.lat,(val)=>{
  mapInit();
})

watch(()=>props.list.length,(val)=>{
  if(val>0) {
    let i= props.list[val-1];

  if(i.lat){
  createCarMarker(props.list[val-1]);
  }
  }
 
 // props.list
})


</script>

<style>
#allmap {
  margin-left: 2%;

  width: 96%;
  height: 65vh;
  box-shadow: 15px 15px 15px #333;
}
</style>