<template>
    <XIASearchBar :searchData="searchData" @search="search"/>
    <div class="title">
       换车日志
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

    </TableComponent>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useSwitchCarLog} from "@/use/sysLog"

const {tableObjet,search,searchData,headers}=useSwitchCarLog()
</script>