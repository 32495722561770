import { $post } from "@/api/base"

export async function index(params) {
    return $post("home/index", params)
}
export async function storeOrderTop10(params) {
    return $post("home/storeOrderTop10", params)
}
export async function leaseInfo() {
    return $post("home/leaseInfo")
}

export async function day7() {
    return $post("home/day7")
}

export async function newOrderList() {
    return $post("home/newOrderList")
}

export async function storeRank() {
    return $post("home/storeRank")
}

export async function getLastSixMonthsOccupancyRate(store_id) {
    return $post("home/getLastSixMonthsOccupancyRate",{store_id})
}

export async function getMonthsOccupancyRate(index=0) {
    return $post("home/getMonthsOccupancyRate",{index})
}




