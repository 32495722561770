import {$post} from "@/api/base"

export async function adminCash(params){
    return $post("finance/adminCash",params)
}
/**
 * 提现规则配置
 *
 */
export async function cashRule(params){
    return $post("finance/cashRule",params)
}

export async function zjtk(params){
    return $post("finance/zjtk",params)
}


/**
 * 更新提现规则配置
 *
 */
 export async function updateCashRule(params){
    return $post("finance/updateCashRule",params)
}
export async function adminCashFlow(params){
    return $post("finance/adminCashFlow",params)
}

/**
 * 
 * 获取商户提现列表
 */
export async function cashReview(params,isfile){
    return $post("finance/cashReview",params,isfile)
}
export async function statistics(){
    return $post("finance/statistics")
}
export async function cashApply(params){
    return $post("finance/cashApply",params)
}
export async function cashReviewConfirm(params){
    return $post("finance/cashReviewConfirm",params)
}

export function freezeCash(params){
    return $post("finance/freezeCash",params)
}

export function getOverView(params){
    return $post("finance/getOverView",params)
}

export function getTklist(params){
    return $post("finance/getTklist",params)
}
export function tkReviewConfirm(params){
    return $post("finance/tkReviewConfirm",params)
}
export function jiedongForId(params){
    return $post("finance/jiedongForId",params)
}






