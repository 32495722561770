<template>
    
    <div class="title">电池配置</div>
     <XIASearchBar @search="search" :searchData="searchData"/>

     <div class="add">
        <el-button @click="isShow=true">新增</el-button>
     </div>
     <TableComponent :headers="headers" :tableObjet="tableObjet">

     </TableComponent>
     <el-dialog v-model="isShow" title="新增电池容量">

        <div class="flex">
            <div style="width: 100px;">
                容量：
            </div>
            <el-input type="text" v-model="capacity"/>
        </div>
        <div class="flex">
            <div style="width: 100px;" >
                价格：
            </div>
            <el-input type="text" v-model="price"/>
        </div>
        <div class="flex">
            <div style="width: 100px;">
                所属商品：
            </div>
            <el-select v-model="goods_id"   filterable>
                    <el-option v-for="o in list" :key="o.value" :label="o.label" :value="o.value" />
            </el-select>
        </div>
            
           
         
            <template #footer>
                <span class="dialog-footer">
                    
                    <el-button type="primary" @click="addBattery()">
                        ok
                    </el-button>
                </span>
            </template>
        </el-dialog>
</template>

<script setup>
import XIASearchBar from "@/components/XIA-SearchBar.vue"
import TableComponent from '@/components/TableComponent.vue';
import {useBattery} from "@/use/shop"
import {getGoodsListToOption,addBatteryForGoodsId} from "@/api/shop"
import {ref} from "vue"
import { ElMessage } from "element-plus";
const isShow=ref(false)
const goods_id=ref()
const capacity=ref()
const price=ref()
const {search,searchData,headers,tableObjet}=useBattery()
const addBattery=()=>{
    if(goods_id.value==null||capacity.value==null||price.value==null){
        ElMessage.error("参数错误")
        return
    }
    addBatteryForGoodsId({goods_id:goods_id.value,price:price.value,capacity:capacity.value}).then(res=>{
        if(res.code==200){
            ElMessage.success("添加成功")
            isShow.value=false
        }else{
            ElMessage.error(res.msg)
        }
    })
    
}
const list=ref([])
getGoodsListToOption().then(res=>{
    list.value=res.data
})

</script>

<style scoped lang="scss">
.add{
    margin-top: 20px;
    width: 100%;
    text-align: right;
    
}
.flex{
    align-items: center;
    margin-bottom: 10px;
}
</style>