import { ref, reactive } from "vue"
import { addGoods, getGoodsList, getBatteryList, getColorList, bannerList, getShopOrderList,fahuo } from "@/api/shop"

import { useBase } from "@/use/base"
import { ElMessage } from "element-plus"
export function useShop() {

    const fromData = reactive({
        type: {
            type: "select",
            label: "商品类型",
            value: 1,
            options: [
                {
                    label: "电动车",
                    value: 1
                },
                {
                    label: "电池",
                    value: 2
                },
                {
                    label: "充电器",
                    value: 2
                },
                {
                    label: "头盔",
                    value: 2
                },
                {
                    label: "通用配件",
                    value: 5
                }
            ],
            require: true,
            placeholder: "请输入商品类型",

        },
        title: {
            type: "text",
            label: "商品名称",
            value: "",
            require: true,
            placeholder: "请输入商品名称",

        },
        price: {
            type: "text",
            label: "基本价格",
            value: "",
            require: true,
            placeholder: "请输入商品价格",

        },
        main_img: {
            type: "image",
            label: "主图",
            value: null,
            require: true,
        },
        loop_1: {
            type: "image",
            label: "轮播图1",
            value: null,
            require: true,
        },
        loop_2: {
            type: "image",
            label: "轮播图2",
            value: null,
            require: true,
        },
        loop_3: {
            type: "image",
            label: "轮播图3",
            value: null,
            require: true,
        },
        loop_4: {
            type: "image",
            label: "轮播图4",
            value: null,
            require: true,
        },
       
        detail_img: {
            type: "image",
            label: "详情图",
            value: null,
            require: true,
        }
    })

    const submit = (data) => {
        addGoods(data).then(res => {
            if (res.code == 200) {
                ElMessage.success("录入成功")
            } else {
                ElMessage.error(res.msg)
            }
        })
    }

    const searchData = reactive([
        {
            label: "商品名称",
            type: "text",
            placeholder: "输入商品名称搜索",
            value: ""
        }
    ])

    const headers = [
        {
            label: "商品名称",
            prop: "title"
        },
        {
            label: "商品类型",
            prop: "type"
        },
        {
            label: "价格",
            prop: "price"
        },
        {
            label: "主图",
            prop: "main_img"
        },
        {
            label: "轮播图",
            prop: "loop"
        },
        {
            label: "详情图",
            prop: "detail_img"
        },
        {
            label: "配色",
            prop: "colors"
        },
        {
            label: "电池容量",
            prop: "batterys"
        },
        {
            label: "操作",
            prop: "caozuo"
        },
    ]
    const { tableObjet, search } = useBase(getGoodsList, searchData);

    return { fromData, submit, tableObjet, search, headers, searchData }
}

export function useBattery() {

    const searchData = reactive({

    })
    const { search, tableObjet } = useBase(getBatteryList, searchData)

    const headers = [
        {
            label: "id",
            prop: "Id"
        },
        {
            label: "容量",
            prop: "capacity"
        },
        {
            label: "价格",
            prop: "price"
        },
        {
            label: "所属商品",
            prop: "title"
        }
    ]
    return { search, tableObjet, searchData, headers }
}

export function useColor() {

    const searchData = reactive({

    })
    const { search, tableObjet } = useBase(getColorList, searchData)

    const headers = [
        {
            label: "id",
            prop: "Id"
        },
        {
            label: "颜色",
            prop: "color"
        },
        {
            label: "价格",
            prop: "price"
        },
        {
            label: "所属商品",
            prop: "title"
        }
    ]
    return { search, tableObjet, searchData, headers }

}

export function useBanner() {
    const searchData = reactive({
        title: {
            type: "text",
            label: "标题"
        }
    })
    const headers = [
        {
            label: "id",
            prop: "id"
        },
        {
            label: "标题",
            prop: "title"
        },
        {
            label: "图片",
            prop: "image"
        },
        {
            label: "跳转地址",
            prop: "url"
        },
        {
            label: "启用状态",
            prop: "state"
        },
        {
            label: "操作",
            prop: "caozuo"
        }
    ]
    const { tableObjet, search } = useBase(bannerList, searchData)

    return { search, searchData, tableObjet, headers }


}

export function useShopOrderList() {

    const headers = [
        {
            label: "ID",
            prop: "Id"
        },
        {
            label: "商品名称",
            prop: "title"
        },
        {
            label: "商品颜色",
            prop: "color"
        },
        {
            label: "商品规格",
            prop: "battery"
        },
        {
            label: "商品价格",
            prop: "price"
        },
        {
            label: "数量",
            prop: "number"
        },
        {
            label: "订单金额",
            prop: "order_price"
        },
        {
            label: "支付金额",
            prop: "pay_price"
        },
        {
            label: "用户手机号",
            prop: "phone"
        },
        {
            label: "收件人",
            prop: "user_name"
        },
        {
            label: "收货地址",
            prop: "address"
        },
        {
            label: "订单状态",
            prop: "state"
        },
        {
            label: "物流状态",
            prop: "wuliu_state"
        },
        {
            label: "操作",
            prop: "caozuo"
        },
    ]
    const searchData = reactive({
        phone:{
            label:"手机号",
            type:"text",
            
        },
        user_name:{
            label:"收件人",
            type:"text",
            
        },
        address:{
            label:"收件地址",
            type:"text",
            
        },
        state:{
            label:"订单状态",
            type:"select",
            options:[
                {
                    label:"待支付",
                    value:"1"
                },
                {
                    label:"已支付",
                    value:"2"
                },
                {
                    label:"已发货",
                    value:"3"
                },
                {
                    label:"已完成",
                    value:"4"
                }
            ]
            
        }
    })

    const queRenFahuo=(params)=>{
        fahuo(params).then(res=>{
            if(res.code==200){
                ElMessage.success(res.msg)
                search()
            }else{
                ElMessage.error(res.msg)
            }

        })
    }
    const { search, tableObjet } = useBase(getShopOrderList, searchData)

    return { headers, searchData, search, tableObjet,queRenFahuo }

}