import {$post} from "./base"
export async function addBanner(parmas){
    return $post("/banner/addBanner",parmas)
}

export async function list(parmas){
    return $post("/banner/list",parmas)
}
export async function edit(parmas){
    return $post("/banner/edit",parmas)
}
export async function remove(parmas){
    return $post("/banner/remove",parmas)
}