import {$post} from  "./base";

export async function addItemModel(parmas){
const data=await $post("itemmodel/add",parmas);
return data;
}
export async function getItemModelList(params){
  
    
    const data=await $post("itemmodel/index",params);
    return data;
    }

    export async function upItemModelInfo(params){
  
        return $post("/itemmodel/upItemModelInfo",params);
     }