import axios from "axios"
import { ElMessage } from "element-plus";
//import {useMainStore} from "../../store/mainStore.js"
//const store=useMainStore()
let config = {
    headers: {'Content-Type': 'multipart/form-data'}
 }
/**
 * 
 * @param {*} url  请求地址不带 baseURL
 * @param {*} params  请求参数 
 * @returns {Object} {msg:"",code:"",data:{}}
 */
export async function $post(url,params=new FormData(),isFile=false){
    const token=localStorage.getItem("token");
    if(params instanceof FormData){
        params.append("token",token);
    }else{
        let p=new FormData();
        for(let i in params){
            p.append(i,params[i]);
        }
        params=p;
        params.append("token",token);
    }
    try {
        
        if(isFile){
            params.append("isFile",true);
            let res=await axios({
                url,
                method:"post",
                responseType:'blob',
                data:params
            })
            console.log(res);
            const url2 = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url2;
            link.setAttribute('download', '数据统计.Xlsx');
            document.body.appendChild(link);
            link.click();
            return
        }
        let {data}=await axios.post(url,params,config);
        
        if(data.code==666){
            ElMessage.error("登录已失效，请重新登录")
            localStorage.clear()
            window.location.href="/"
            //

        }
        
        return data;
    } catch (error) {
        console.log(error);
        ElMessage.error(error.message)
        return Promise.resolve({msg:"服务器错误",code:500})
    }

}
/**
 * 
 * @param {*} url  请求地址不带 baseURL
 * @param {*} params  请求参数 
 * @returns {Object} {msg:"",code:"",data:{}}
 */
 export async function $get(url,params=new FormData()){
    const token=localStorage.getItem("token");
    if(params instanceof FormData){
        params.append("token",token);
    }else{
        let p=new FormData();
        for(let i in params){
            p.append(i,params[i]);
        }
        params=p;
        params.append("token",token);
    }
    try {
        let {data}=await axios.get(url,params,config);
        return data;
    } catch (error) {
        return Promise.resolve({msg:"连接服务器超时",code:500})
    }

}