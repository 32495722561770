<template>

<div v-if="store.user_type==1">
        ......
</div>
<div v-else-if="store.user_type==6">
    <FormComponent @submit="submit" title="提现申请" :data="data" canEdit="true" submitButtonText="提交"></FormComponent>

</div>
<div v-else>
....
</div>
</template>
<script setup>
import {useMainStore} from "../../../store/mainStore"
import FormComponent from '@/components/FormComponent.vue';
const store=useMainStore()
import {useTx} from "@/use/jms"
const {data,submit}=useTx();

</script>