<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">商品列表</div>
    <TableComponent :headers="headers" :tableObjet="tableObjet">

        <template #caozuo="scoped">

            <el-button @click="swtichGoodsState(scoped.$index, scoped.row)" type="primary"  >
            
                {{ scoped.row.is_can_buy==1?"下架":"上架" }}
            </el-button>
          <el-button @click="handleEdit(scoped.$index, scoped.row)" type="primary" icon="Edit" circle></el-button>
        </template>
        <template #main_img="scoped">


            <img :src="url + scoped.row.main_img" alt="" width="200" @click="selectImg=scoped.row.main_img;dialogFormVisible=true">
        </template>
        <template #type="scoped">
           <el-tag> {{scoped.row.type==1?"电动车":"配件"}}</el-tag>
        </template>
        <template #price="scoped">
           <el-tag> {{scoped.row.price}}</el-tag>
        </template>
        <template #colors="scoped">
           <el-tag v-for="i in scoped.row.colors" :key="i.Id" style="margin-right: 10px;"> {{i.color}}:{{i.price }}元</el-tag>
        </template>
        <template #batterys="scoped">
           <el-tag v-for="i in scoped.row.batterys" :key="i.Id" style="margin-right: 10px;"> {{i.capacity}}:{{i.price }}元</el-tag>
        </template>
        <template #loop="scoped">

            <div class="loop">
                <img :src="url + scoped.row.loop_img.split(',')[0]" alt="" height="200" @click="selectImg=scoped.row.loop_img.split(',')[0];dialogFormVisible=true"/>
                <img :src="url + scoped.row.loop_img.split(',')[1]" alt="" height="200" @click="selectImg=scoped.row.loop_img.split(',')[1];dialogFormVisible=true"/>
                <img :src="url + scoped.row.loop_img.split(',')[2]" alt="" height="200" @click="selectImg=scoped.row.loop_img.split(',')[2];dialogFormVisible=true"/>
                <img :src="url + scoped.row.loop_img.split(',')[3]" alt="" height="200" @click="selectImg=scoped.row.loop_img.split(',')[3];dialogFormVisible=true"/>
                <img :src="url + scoped.row.loop_img.split(',')[4]" alt="" height="200" @click="selectImg=scoped.row.loop_img.split(',')[4];dialogFormVisible=true"/>
            </div>
        </template>
        <template #detail_img="scoped">

            <img :src="url + scoped.row.detail_img" alt="" height="200" @click="selectImg=scoped.row.detail_img;dialogFormVisible=true">
        </template>

    </TableComponent>

    <el-dialog v-model="dialogFormVisible" title="预览">
            
                
            <img :src="url+selectImg"
                    alt=""  @click="fangda(item.row)">
            
           
            
            <template #footer>
                <span class="dialog-footer">
                    
                    <el-button type="primary" @click="dialogFormVisible = false">
                        ok
                    </el-button>
                </span>
            </template>
        </el-dialog>
</template>

<script setup>
import XIASearchBar from "@/components/XIA-SearchBar.vue"
import TableComponent from '@/components/TableComponent.vue';
import {ref,inject,reactive} from "vue"
import { useShop } from "@/use/shop"
import {updateGoodsInfo,swtichGoodsStateBuId} from "@/api/shop"
import { ElMessage } from "element-plus";
const { search, searchData, headers, tableObjet } = useShop()
const url = "http://127.0.0.1/storage/"
const dialogFormVisible=ref(false)
const selectImg=ref("")
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")


const swtichGoodsState=(index, item)=>{
    swtichGoodsStateBuId(item.Id).then(res=>{
        ElMessage.info(res.msg)
        search()
    })
}
const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.Id,
            canEdit: false,
            type: "text"
        },
        title: {
            label: "商品名称",
            
            value: item.title,
            canEdit: true,
            type: "text"
        },
        name: {
            label: "商品类型",
            
            value: item.type,
            canEdit: true,
            type: "text"
        },
        price: {
            label: "商品价格",
            
            value: item.price,
            canEdit: true,
            type: "text"
        },
       
        main_img: {
            label: "主图",
            
            value:"",
            canEdit: true,
            type: "image"
        },
        loop_img1: {
            label: "轮播图1",
            
            value: "",
            canEdit: true,
            type: "image"
        },
        loop_img2: {
            label: "轮播图2",
            
            value: "",
            canEdit: true,
            type: "image"
        },
        loop_img3: {
            label: "轮播图3",
            
            value: "",
            canEdit: true,
            type: "image"
        },
        loop_img4: {
            label: "轮播图4",
            
            value: "",
            canEdit: true,
            type: "image"
        },
        detail_img: {
            label: "详情图",
            
            value: "",
            canEdit: true,
            type: "image"
        },
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateGoodsInfo
    drawOptions.formData = formData
    drawOptions.title = "修改门店信息"
    isOpenDrawer.value = true
}
</script>

<style scoped lang="scss">
.loop {
    width: 500px;
    height: 250px;
    display: flex;
    overflow-x: scroll;
    padding: 10px;
    box-sizing: border-box;
    img {
        margin-right: 20px;
        border:1px solid #f00;
        padding: 2px;
    }
}
</style>