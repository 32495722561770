<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"></XIASearchBar>
        <div class="title">门店提现配置</div>
        <XIATable :tableObjet="tableObjet" :headers="headers">
            <template #pay_type="scope">
               {{getPayType(scope.row.pay_type)}}
            </template>
            <template #fee_type="scope">
                <div v-if="scope.row.fee_type == 1">
                    百分比
                </div>
                <div v-else-if="scope.row.fee_type == 2">
                    固定
                </div>
            </template>
            <template #caozuo="scope">

                <el-button @click="edit(scope.row)" type="primary" icon="Edit" circle />

            </template>
        </XIATable>
    </div>
</template>


<script setup>
import XIASearchBar from '@/components/XIA-SearchBar.vue'
import XIATable from "@/components/TableComponent.vue"
import { useCashRule } from "@/use/finance"
const { search, searchData, tableObjet, headers, edit } = useCashRule()
const getPayType=(type)=>{
const types=["","支付宝","支付宝"]
return types[type]

}
</script>

<style lang="scss" scoped>

</style>