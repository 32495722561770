import {$post} from "./base"

export async function leaseDataAdmin(params,isFile){
    return $post("/statistics/leaseData_admin",params,isFile)
}

export async function deviceTJ(params){
    return $post("/statistics/deviceTJ",params)
}

export async function getYewuyuanTj(params){
    return $post("/statistics/getYewuyuanTj",params)
}
