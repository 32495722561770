<template>
    <XIASearchBar :searchData="searchData" @search="search"/>
    <div class="title">
       设备日志
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">
        <template #type="s">
        {{ types[s.row.type] }}</template>
    </TableComponent>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useLILog} from "@/use/sysLog"

const {tableObjet,search,searchData,headers}=useLILog()
const types=['','默认','投放','修改','转入','转出','回收']
</script>