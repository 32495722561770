import { ref,reactive,watch} from "vue"
import {index,storeOrderTop10,leaseInfo,day7,newOrderList,storeRank,getLastSixMonthsOccupancyRate,getMonthsOccupancyRate} from "@/api/home"
import { useMainStore } from "../../store/mainStore.js"
export function useHome(){
    const monthIndex=ref(0)
    const store = useMainStore();
    const homeData=reactive({
        leaseInfo:{

        },
        storeRank:[],
        newOrderList:[],
        lastSixMonthsOccupancyRate:[],
        monthsOccupancyRate:[],
        yj:0
    })
    storeRank().then(res=>{
        if(res.code==200){
            homeData.storeRank=res.data
        }
    })
    index().then((res)=>{
        let data=res.data
        homeData.userCount=data.userCount
        homeData.totalRevenue=data.totalRevenue
        homeData.adminCount=data.adminCount
        homeData.storeCount=data.storeCount
        homeData.orderCount=data.orderCount
        homeData.todayOrderCount=data.todayOrderCount
        homeData.totalLeaseCount=data.totalLeaseCount
        homeData.yj=data.yj
        

        
        
    })
    getMonthsOccupancyRate(monthIndex.value).then(res=>{
        if(res.code==200){
            let data=res.data
            homeData.monthsOccupancyRate=data.monthsOccupancyRate
        }
    })

    watch(monthIndex,(v)=>{
        getMonthsOccupancyRate(monthIndex.value).then(res=>{
            if(res.code==200){
                let data=res.data
                homeData.monthsOccupancyRate=data.monthsOccupancyRate
            }
        })
    })
    watch(()=>store.curr_select_store_id,i=>{
        getLastSixMonthsOccupancyRate(i).then(res=>{
            if(res.code==200){
                let data=res.data
    
            homeData.lastSixMonthsOccupancyRate=data
            }
        })
    })
    if(store.curr_select_store_id){
        getLastSixMonthsOccupancyRate(store.curr_select_store_id).then(res=>{
            if(res.code==200){
                let data=res.data
    
            homeData.lastSixMonthsOccupancyRate=data
            }
        })
       
    }
    storeOrderTop10().then(res=>{
        let data=res.data
        homeData.storeOrderTop10=data
    })
    leaseInfo().then(res=>{
        let data=res.data
        homeData.leaseInfo=data
    })
    day7().then(res=>{
        let data=res.data
        homeData.day7=data
    })
    newOrderList().then(res=>{
        if(res.code==200){
            homeData.newOrderList=res.data
        }
    })
    return {homeData,monthIndex}
}