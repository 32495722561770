import { statistics, freezeCash, adminCash, adminCashFlow, cashRule, updateCashRule, cashReview, cashApply, cashReviewConfirm,getTklist,tkReviewConfirm } from "@/api/finance"
import { reactive, ref } from "vue"
import { useBase, useHandleEdit } from "@/use/base"
import { ElMessage } from "element-plus";
/**
 * 商户资金列表
 * 
 */
export function useAdminCash() {
    const headers = [
        {
            label: "ID",
            prop: "id"
        },
        {
            label: "前缀",
            prop: "pre_name"
        },
        {
            label: "门店名",
            prop: "name"
        },
        {
            label: "可提现资金余额",
            prop: "balance"
        },
        {
            label: "待解冻资金",
            prop: "freeze_balance"
        },
        {
            label: "总资金",
            prop: "total_balance"
        }
    ]
    const searchData = reactive({
        
        
    })
    //const data = ref([]);
    const statisticsData = ref([]);
    const { search, tableObjet } = useBase(adminCash, searchData,false,true)
    //adminCash().then(res => data.value = res.data)
    statistics().then(res => statisticsData.value = res.data)
    return { headers, statisticsData, search, tableObjet,searchData }
}

/**
 * 
 * 商户资金流水
 */
export function useAdminCahFlow() {
    // const types = ["", "收取押金", "收取租金","押金退回","平台收取分成","提现申请","提现驳回"]
    const searchData = reactive({
        create_time: {
            value: "",
            type: "datePick",
            label: "选择日期"
        },
        type: {
            value: "",
            type: "select",
            label: "变动类型",
            options:
                [
                    {
                        label: "收取租金",
                        value: 2
                    },
                    {
                        label: "平台收取分成",
                        value: 4
                    },
                    {
                        label: "提现申请",
                        value: 5
                    },
                    {
                        label: "提现驳回",
                        value: 6
                    },
                ]
        }
    })
    const headers = [
        {
            label: "ID",
            prop: "id"
        },
        {
            label: "门店",
            prop: "name"
        },
        {
            label: "变动前金额",
            prop: "balance_pre"
        },
        {
            label: "变动金额",
            prop: "price"
        },
        {
            label: "变动后金额",
            prop: "balance_post"
        },
        {
            label: "类型",
            prop: "type"
        },
        {
            label: "变动时间",
            prop: "create_time"
        },
        {
            label: "关联订单",
            prop: "order_num"
        },
        {
            label: "备注",
            prop: "remark"
        }

    ]

    const { tableObjet, search } = useBase(adminCashFlow, searchData, false, true)
    // adminCashFlow().then(res => data.value = res.data)

    return { searchData, headers, tableObjet, search }
}
/**
 * 商户提现配置
 */
export function useCashRule() {
    const searchData = reactive({
        name: {
            label: "门店名",
            type: "text",
            value: "",
            placeholder: "输入门店名"
        },
        account_name: {
            label: "收款户名",
            type: "text",
            value: "",
            placeholder: "输入收款户名"
        },
        account_number: {
            label: "收款账号",
            type: "text",
            value: "",
            placeholder: "输入收款账号"
        }
    })
    const headers = [{
        label: "ID",
        prop: 'Id'
    },
    {
        label: "前缀",
        prop: 'pre_name'
    },
    {
        label: "门店名",
        prop: 'name'
    },

    {
        label: "收款户名",
        prop: "account_name"
    },
    {
        label: "收款支付宝",
        prop: "account_number"
    },
    // {
    //     label: "收款微信手机号",
    //     prop: "wx_phone"
    // },
    // {
    //     label: "open_id",
    //     prop: "open_id"
    // },
    {
        label: "收款方式",
        prop: "pay_type"
    },
    // {
    //     label: "提现费率",
    //     prop: "fee"
    // },
    {
        label: "提现间隔（月）",
        prop: "interval"
    },
    {
        label: "操作",
        prop: "caozuo"
    }
    ]
    const { search, tableObjet } = useBase(cashRule, searchData);
    const { handleEtid } = useHandleEdit();
    const edit = (item) => {
        const formData = reactive({
            id: {
                value: item.Id,
                canEdit: false,
                label: "ID",
                type: 'text'

            },
            admin_id: {
                value: item.name,
                canEdit: false,
                label: "商户名",
                type: 'text'

            },
            account_name: {
                value: item.account_name,
                canEdit: false,
                label: "收款账户名",
                type: 'text'

            },
            account_number: {
                value: item.account_number,
                canEdit: false,
                label: "收款支付宝",
                type: 'text'

            },
            // wx_phone: {
            //     value: item.wx_phone,
            //     canEdit: false,
            //     label: "微信手机号",
            //     type: 'text'

            // },
            // open_id: {
            //     value: item.open_id,
            //     canEdit: false,
            //     label: "open_id",
            //     type: 'text'

            // },
            // pay_type: {
            //     value: item.pay_type,
            //     canEdit: true,
            //     label: "支付类型",
            //     type: 'select',
            //     options: [{
            //         label: "微信",
            //         value: 1
            //     },
            //     {
            //         label: "支付宝",
            //         value: 2
            //     }]

            // },
            interval: {
                value: item.interval,
                canEdit: true,
                label: "提现间隔(月)",
                type: 'text'

            },
            fee_type: {
                value: item.fee_type,
                canEdit: true,
                label: "费率类型",
                type: 'select',
                options: [{
                    label: "百分比",
                    value: 1
                },
                {
                    label: "固定",
                    value: 2
                }]

            },
        })
        handleEtid(formData, updateCashRule, "修改提现规则")
    }
    return { search, tableObjet, searchData, headers, edit }
}

/**prop
 * 商户提现审核
 */
export function useCashReview() {
    const searchData = reactive({
        pre_name: {
            value: "",
            placeholder: "输入门店前缀进行搜索",
            type: "text",
            label: "门店前缀"
        },
        name: {
            value: "",
            placeholder: "输入门店名进行搜索",
            type: "text",
            label: "门店名"
        },
        state: {
            value: 1,
            placeholder: "选择状态",
            type: "select",
            label: "审核状态",
            options: [
                {
                    label: "未审核",
                    value: 1
                },
                {
                    label: "已完成",
                    value: 3
                },
                {
                    label: "已拒绝",
                    value: 4
                }
            ]

        },
        create_time: {
            value: "",
            type: "datePick",
            label: "申请时间"
        },

    })
    const headers = [{
        label: "id",
        prop: "Id"
    },
    {
        label: "门店前缀",
        prop: "pre_name"
    },
    {
        label: "门店",
        prop: "name"
    },
    {
        label: "收款户名",
        prop: "account_name"
    },
    {
        label: "收款账号",
        prop: "account_number"
    },
    {
        label: "提现金额",
        prop: "balance"
    },
    {
        label: "状态",
        prop: "state"
    },
    {
        label: "申请时间",
        prop: "create_time"
    },
    {
        label: "审核时间",
        prop: "review_time"
    },
    {
        label: "操作",
        prop: "caozuo"
    }];
    const editHandle = (item, status,pass) => {
        ElMessage.info("正在处理")

        
        cashReviewConfirm({ id: item.Id, status,pass }).then(res => {
            
            if (res.code == 200) {
                search()
                ElMessage.success(res.msg)
            } else {
                ElMessage.error(res.msg)
            }
            
        })
    }
    const { search, tableObjet } = useBase(cashReview, searchData)
    return { search, searchData, headers, tableObjet, editHandle }
}
/**prop
 * 商户提现审核
 */
export function useTK() {
    const searchData = reactive({
        name: {
            value: "",
            placeholder: "输入门店名",
            type: "text",
            label: "门店"
        },
        state: {
            value: 1,
            placeholder: "选择状态",
            type: "select",
            label: "审核状态",
            options: [
                {
                    label: "未审核",
                    value: 1
                },
                {
                    label: "已完成",
                    value: 2
                },
                {
                    label: "已拒绝",
                    value: 3
                }
            ]

        },
        create_time: {
            value: "",
            type: "datePick",
            label: "申请时间"
        },

    })
    const headers = [{
        label: "id",
        prop: "id"
    },
    {
        label: "门店前缀",
        prop: "pre_name"
    },
    {
        label: "门店",
        prop: "name"
    },
    {
        label: "订单号",
        prop: "order_num"
    },
    {
        label: "订单金额",
        prop: "total"
    },
    {
        label: "退款金额",
        prop: "refund"
    },
    {
        label: "审核状态",
        prop: "state"
    },
    {
        label: "申请时间",
        prop: "create_time"
    },
    {
        label: "审核时间",
        prop: "review_time"
    },
    {
        label: "备注",
        prop: "remark"
    },
    {
        label: "操作",
        prop: "caozuo"
    }];
    const editHandle = (item, status,pass) => {
        ElMessage.info("正在处理")

        
        tkReviewConfirm({ id: item.id, status,pass }).then(res => {
            
            if (res.code == 200) {
                search()
                ElMessage.success(res.msg)
            } else {
                ElMessage.error(res.msg)
            }
            
        })
    }
    const { search, tableObjet } = useBase(getTklist, searchData)
    return { search, searchData, headers, tableObjet, editHandle }
}
/**
 * 提现申请
 */
export function useCashApply() {
    const submit = (formData) => {
        cashApply(formData).then(res => {
            if (res.code == 200) {
                ElMessage.success(res.msg)
            } else {
                ElMessage.error(res.msg)
            }
        })

    }
    const data = reactive({
        balance: {
            label: "提现金额",
            type: "text",
            require: true,
            value: ""
        }
    })
    return { submit, data }


}

export function useFreezeCash() {
    const searchData = reactive({
        state:{
            label:"状态",
            type:"select",
            placeholder:"选择资金状态",
            value:"",
            options:[
                {
                    label:"待解冻",
                    value:"1"
                },
                {
                    label:"已解冻",
                    value:"2"
                }
            ]
        },
        type:{
            label:"类型",
            type:"select",
            placeholder:"选择资金类型",
            value:"",
            options:[
                {
                    label:"默认",
                    value:"1"
                },
                {
                    label:"次月解冻",
                    value:"3"
                }
            ]
        }
    })
    const headers = [{
        label: "id",
        prop: "Id"
    },
   
    {
        label: "门店前缀",
        prop: "pre_name"
    } ,
    {
        label: "门店",
        prop: "name"
    }
        ,
    {
        label: "金额",
        prop: "balance"
    }

        ,
    {
        label: "关联订单号",
        prop: "order_num"
    },
    {
        label: "车架号",
        prop: "device_car_number"
    }, 
    {
        label: "是否在本门店",
        prop: "notIn"
    }, 
    {
        label: "类型",
        prop: "type"
    },
    {
        label: "状态",
        prop: "state"
    }
    , {
        label: "创建日期",
        prop: "create_time"
    },
    {
        label: "备注",
        prop: "remark"
    },
    {
        label:"操作",
        prop:"caozuo"
    }
]
    const { search, tableObjet } = useBase(freezeCash, searchData, null, true)

    return { headers, searchData, search, tableObjet }
}