<template>
    <div>
        <XIASearchBar @search="search" :searchData="searchData"/>   
        <div class="title">设备事件</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet">
           <template #createTime="s">
                    {{getTime(s.row.createTime) }}
           </template>

        </TableComponent>
    </div>
</template>

<script  setup>
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import TableComponent from '@/components/TableComponent.vue';
import {useDeviceEventData} from "@/use/sysLog"



const {headers,tableObjet,search,searchData}=useDeviceEventData();

const getTime=(ts)=>{

     ts=Number(ts)
   
    let time=new Date(ts)
    
    return time.getFullYear()+"-"+(time.getMonth()+1)+"-"+time.getDate()+":"+time.getHours()+":"+time.getMinutes()
}

</script>

<style lang="scss" scoped>

</style>