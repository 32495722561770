<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"/>
        <div class="add">

            <el-button class="btn" type="primary" @click="add">新增</el-button>
        </div>
        <TableComponent :tableObjet="tableObjet" :headers="headers">
            <template #caozuo="scope">
                <el-button @click="handleEdit(scope.$index, scope.row)" type="primary" icon="Edit" circle />
            </template>
            <template #type="item">
                {{ item.row.type == 1 ? "按次数" : "次月提现" }}
            </template>
            <template #min_lease_time="item">
                {{ item.row.type == 1 ? item.row.min_lease_time + "周" : item.row.min_lease_time + "月" }}
            </template>
        </TableComponent>
    </div>
</template>
    
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useRouter } from 'vue-router'
import {reactive,inject} from "vue"
import { useGetBillingRules } from "@/use/billingRule"
import {updateBillingRule} from "@/api/billingrule"
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")

const { headers,tableObjet,search,searchData } = useGetBillingRules();
const router = useRouter()

const add = () => {
    router.push({ path: "/leaseitem/addbillingrule" })
}

const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "所属门店",
            
            value: item.name,
            canEdit: false,
            type: "text"
        },
        rule_name: {
            label: "规则名称",
            
            value: item.rule_name,
            canEdit: true,
            type: "text"
        },

        type: {
            label: "规则类型",
            options:[
                {
                    label:"按次数",
                    value:1
                },
                {
                    label:"次月提现",
                    value:3
                }
            ],
            value: item.type,
            canEdit: true,
            type: "select"
        },
        price: {
            label: "30租金",
            
            value: item.price,
            canEdit: true,
            type: "text"
        },
        day_15_price: {
            label: "15天租金",
            
            value: item.day_15_price,
            canEdit: true,
            type: "text"
        },
        day_60_price: {
            label: "60天租金",
            
            value: item.day_60_price,
            canEdit: true,
            type: "text"
        },
        day_90_price: {
            label: "90天租金",
            
            value: item.day_90_price,
            canEdit: true,
            type: "text"
        },
        min_lease_count: {
            label: "最低租赁天数",
            
            value: item.min_lease_count,
            canEdit: true,
            type: "text"
        },
        xf_youhui: {
            label: "续租优惠",
            
            value: item.xf_youhui,
            canEdit: true,
            type: "text"
        },
        fee: {
            label: "平台分成比例",
            
            value: item.fee,
            canEdit: true,
            type: "text"
        },
        gd_fee: {
            label: "平台固定分成",
            
            value: item.gd_fee,
            canEdit: true,
            type: "text"
        },
        remark: {
            label: "备注",
            value: item.remark,
            canEdit: true,
            type: "text"
        }
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateBillingRule
    drawOptions.formData = formData
    drawOptions.title = "修改计费规则"
    isOpenDrawer.value = true
}

</script>
    
<style scoped lang="scss">
.add {
    margin-top: 20px;
    .btn{
        width: 100px;
        margin-right: 20px;
    }
}
</style>