<template>
<div>
        <XIASearchBar :searchData="searchData" @search="search"/>
        <div class="title">
            物品型号管理
        </div>
        <div class="add">
            
            <el-button class="btn" type="primary" @click="add">新增</el-button>
        </div>
        <TableComponent :tableObjet="tableObjet" :headers="headers">
               <template #caozuo="item">
                    
                <el-button @click="handleEdit(item.$index, item.row)" type="primary" icon="Edit" circle />
                    <el-button type="danger" icon="Delete" circle @click="remvoe(item.row)" />
                   
               </template>
               <template #big_img="scope">
               <img :src="'/storage/'+scope.row.big_img" alt="">
            </template>
            <template #min_img="scope">
               <img :src="'/storage/'+scope.row.min_img" alt="">
            </template>
               <template #type="item">
                    {{item.row.type==1?"电动车":"电池"}}
               </template>
        </TableComponent>
</div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import { useRouter } from 'vue-router'
import {useGetItemModelList} from "@/use/itemModel"
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {upItemModelInfo} from "@/api/itemModel"
import {reactive,inject} from "vue"
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")

const {tableObjet,searchData,remvoe,search}=useGetItemModelList();
const router = useRouter()

const add=()=>{
    router.push({path:"/leaseitem/additemmodel"})
}
const click=(i)=>{
    router.push({path:"/leaseitem/additemmodel",query:i})
}
let headers=[

    {
        prop:"id",
        label:"ID"
    },
    {
        prop:"name",
        label:"所属门店"
    },
    {
        prop:"model",
        label:"物品型号"
    },
    {
        prop:"type",
        label:"租赁类目"
    },
    {
        prop:"deposit",
        label:"押金"
    },
    {
        prop:"v",
        label:"电池电压"
    },
    {
        prop:"a",
        label:"电池电流"
    },
    {
        prop:"mah",
        label:"电池容量"
    },
    {
        prop:"feature",
        label:"特色"
    },
    {
        prop:"big_img",
        label:"大图",
    
    },
    {
        prop:"min_img",
        label:"小图"
    },
    {
        prop:"caozuo",
        label:"操作"
    }
];
const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "所属门店",
            
            value: item.name,
            canEdit: false,
            type: "text"
        },
        type: {
            canEdit: false,
            label: "租赁类目",
            placeholder: "请选择租赁类目",
            value: item.type,
            options: [{
                value:2,
                label:"电池"
            },
        {
            value:1,
            label:"电动车"
        }],
            
            type: "select"
        },
        model:{
            label: "物品型号",
            
            value: item.model,
            canEdit: true,
            type: "text"
        },
        deposit: {
            label: "押金",
            
            value: item.deposit,
            canEdit: true,
            type: "text"
        },
        big_img: {
            label: "大图",
            
            value: "/storage/"+item.big_img,
            canEdit: true,
            type: "image"
        },
        min_img: {
            label: "小图",
            
            value: '/storage/'+item.min_img,
            canEdit: true,
            type: "image"
        },
        v: {
            label: "电压",
            
            value: item.v,
            canEdit: true,
            type: "text"
        },
        
        a: {
            label: "电流",
            
            value: item.v,
            canEdit: true,
            type: "text"
        },
        mah: {
            label: "容量",
            
            value: item.mah,
            canEdit: true,
            type: "text"
        },
        feature: {
            label: "特色",
            value: item.feature,
            canEdit: true,
            type: "text"
        },
        
        description: {
            label: "描述",
            value: item.description,
            canEdit: true,
            type: "text"
        }
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = upItemModelInfo
    
    drawOptions.formData = formData
    drawOptions.title = "修改物品型号"
    isOpenDrawer.value = true
}


</script>

<style scoped lang="scss">

img{
    width: 200px;
    height: 100px;
}
</style>