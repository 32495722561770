<template>
    <div>
        <div>
            <XIASearchBar :searchData="searchData" @search="search"  @daochu="search(true)"/>
            <div class="title">提现总览</div>

            <div class="flex zonlan">
                <div class="flex_1 item flex">
                    <div class="zs">

                    </div>
                    <div class="b">
                        总
                    </div>
                    <div class="flex_1 c">
                        <div class="price">￥{{ tableObjet.otherData.total_cash }}</div>
                        <div>
                            门店可提现金额
                        </div>
                    </div>
                </div>
                <div class="flex_1 item flex">
                    <div class="zs">

                    </div>
                    <div class="b">
                        待
                    </div>
                    <div class="flex_1 c">
                        <div class="price">￥{{ tableObjet.otherData.pending_cash }}</div>
                        <div>
                            待审核
                        </div>
                    </div>
                </div>
                <div class="flex_1 item flex">
                    <div class="zs">

                    </div>
                    <div class="b">
                        已
                    </div>
                    <div class="flex_1 c">
                        <div class="price">￥{{ tableObjet.otherData.processed_cash }}</div>
                        <div>
                            已提现金额
                        </div>
                    </div>
                </div>
            </div>

            <div class="title">申请列表</div>
            <TableComponent :headers="headers" :tableObjet="tableObjet">

                <template #state="scope">
                    <el-tag>{{ getState(scope.row.state) }}</el-tag>
                </template>
                <template #caozuo="scope">


                    <el-dropdown>
                        <el-button type="primary">
                            操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>
                                    <div v-if="scope.row.state == 2">
                                        <div @click="shenhe(scope.row)">打款完成</div>

                                    </div>



                                </el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.state == 1">
                                    <div>
                                        <div @click="shenhe(scope.row)">审核</div>

                                    </div>



                                </el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.state == 1">

                                   

                                        <el-popconfirm confirm-button-text="驳回" cancel-button-text="取消" 
                                        icon-color="#626AEF" title="是否驳回申请?" @confirm="editHandle(scope.row, false)"
                                        >
                                        <template #reference>
                                            驳回
                                        </template>
                                    </el-popconfirm>

                                       
                                    


                                </el-dropdown-item>

                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>

                </template>
            </TableComponent>


        </div>
        <el-dialog v-model="dialogFormVisible" title="提现审核"  >
            <div class="flex d">
                <div class="l">门店</div>
                <div style="font-weight: bold;" class="r">{{ currItem.pre_name+"-"+currItem.name }}</div>
            </div>
            <div class="flex d">
                <div  class="l">提现金额</div>
                <div style="color:red;font-weight: bold;" class="r">{{ currItem.balance }}</div>
            </div>
            <div class="flex d">
                <div  class="l">收款姓名</div>
                <div class="r"> <el-tag>{{ currItem.account_name }}</el-tag></div>
            </div>
            <div class="flex d">
                <div  class="l">收款支付宝</div>
                <div class="r"><el-tag>{{ currItem.account_number }}</el-tag></div>
            </div>
            <div class="flex d">
                <div  class="l">当前状态</div>
                <div class="r"><el-tag>{{ getState(currItem.state) }}</el-tag></div>
            </div>
            <div class="flex d">
                <div  class="l">操作密码</div>
                <div class="r"><el-input type="password" name="" id="" v-model="pass" placeholder="请输入操作密码"/></div>
            </div>
            <template #footer>

                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取消</el-button>
                    <el-button type="primary" @click="commit()">
                        {{ currItem.state == 1 ? ' 通过' : '已打款' }}
                    </el-button>
                </span>
                
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useCashReview } from "@/use/finance"
import { ElMessage } from 'element-plus';
import { reactive, ref } from "vue"
const { search, searchData, headers, tableObjet, editHandle } = useCashReview();
const getState = (state) => {
    const states = ["", "待审核", "待打款", "已完成", "已驳回","转账未能确定是否成功"]
    return states[state]
}
const dialogFormVisible = ref(false)
const currItem = ref({})
const pass = ref("")
const shenhe = (item) => {
    currItem.value = item
    dialogFormVisible.value = true
}
const commit = () => {
    if (pass.value == "") {
        ElMessage.error("请输入操作密码")
        return
    }
    dialogFormVisible.value = false
    editHandle(currItem.value, true, pass.value)
}
</script>

<style lang="scss" >
.d {
    height: 50px;
    display: flex;
   justify-content: center;
   align-items: center;
    div {
        margin-right: 20px;
    }
    .l{
        width: 10vw;
        text-align: right;
        color: #999;
    }
    .r{
       
        text-align: left;
        width: 15vw;
    }
    

    input {
        border: 0;
        height: 30px;
        line-height: 30px;
        background-color: #fff;
        border-radius: 5px;

    }
}
 .el-dialog__header{
    text-align: center !important;
    font-size: 25px;
    font-weight: bold;
}
.zonlan {
    flex: 1;
    width: 100%;
    margin-top: 20px;

    .item {
        background-color: #fff;
        height: 100px;
        border-radius: 10px;
        margin: 0 50px;
        border: 1px solid rgb(161, 215, 244);
        align-items: center;

        .zs {
            background-color: var(--el-color-primary);
            width: 10px;
            border-radius: 10px;
            margin-right: 50px;
            height: 100%;
        }

        .b {
            color: #999;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border: 5px solid var(--el-color-primary);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .c {
            text-align: center;
            color: #999;
            font-size: 13px;

            .price {
                margin-bottom: 10px;
                color: var(--el-color-primary);
                font-size: 25px;
            }
        }
    }

}</style>