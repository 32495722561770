<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"/>
        <div class="title">资金流水</div>
        <TableComponent :data="data" :headers="headers" :tableObjet="tableObjet" :height="750">
           <template #type="item">
            {{item.row.type==1?"押金":"租金"}}
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import {useCashFlow} from "@/use/user"
import XIASearchBar from '@/components/XIA-SearchBar.vue';

const {searchData,headers,data,search,tableObjet}=useCashFlow();
</script>

<style lang="scss" scoped>

</style>