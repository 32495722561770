<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"/>
        <div class="title">租赁物品列表</div>
        
        <TableComponent :headers="headers" :tableObjet="tableObjet" :psize="5">
            <template #deposit="item">
                    <div class="red">
                        {{item.row.deposit}}
                    </div>
            </template>
            <template #price="item">
                    <el-tag class="rebeccapurple">
                        {{item.row.price}}
                    </el-tag>
            </template>
            <template #d15_price="item">
                    <el-tag class="rebeccapurple">
                        {{item.row.d15_price}}
                    </el-tag>
            </template>
            <template #qrcode="item">
                   <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data=https://jimove.jimoveebikes.com/'+item.row.device_number" alt="" width="100">
                        
                   
            </template>
            <template #state="item">
                    <el-tag>{{getState(item.row.state)}}</el-tag>
            </template>
            <template #lock_state="item">
                
                    <el-switch :value="item.row.lock_state==1?true:false" disabled></el-switch>
            </template>
            <template #type="item">
                    {{getType(item.row.type)}}
            </template>
            <!-- <template #active_state="item">
                    <el-tag>{{item.row.active_state?'已激活':'未激活'}}</el-tag>
            </template> -->
            <!-- <template #active_code="item">
                <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data='+item.row.active_code" alt="" width="100">
            </template> -->
            
            
            <template #caozuo="item">
                    <RouterLink :to="'/leaseitem/detail?device_number='+item.row.device_number"><el-button  circle type="primary"  ><el-icon><Memo /></el-icon></el-button></RouterLink>
                    
                    <el-button @click="handleEdit(item.$index, item.row)" type="primary" icon="Edit" circle  :disabled="item.row.state!=0"/>
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useLeaseItemList} from "@/use/leaseitems"
import { ElMessage } from 'element-plus';
import {reactive,inject} from "vue"
import {updateLeaseItem} from "@/api/leaseItem"
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const {headers,tableObjet,search,searchData}=useLeaseItemList();
let width=window.document.body.clientWidth
if(width<=1000){
    headers.value=[{
        label:"门店",
        prop:"name"
    }]
}

//headers
const getState=(state)=>{

    const states={
        0:"闲置",
        1:"租赁中"
    };
    return states[state]
}
const getType=(type)=>{

const types={
    1:"按周",
    2:"按月"
};
return types[type]
}

const handleEdit = (index, item) => {
    if(item.state!=0){
        ElMessage.error("只有闲置中的物品才能修改!")
        return
    }
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "所属门店",
            
            value: item.name,
            canEdit: false,
            type: "text"
        },
        device_number: {
            label: "设备号",
            
            value: item.device_number,
            canEdit: true,
            type: "text"
        },
       item_number: {
            label: "物品编号",
            
            value: item.item_number,
            canEdit: true,
            type: "text"
        },
        
        device_car_number: {
            label: "车架号",
            
            value: item.device_car_number,
            canEdit: true,
            type: "text"
        },
        lock_state: {
            label: "能否出租/使用",
            
            value: item.lock_state==1?true:false,
            canEdit: true,
            type: "switch"
        }
        
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateLeaseItem
    drawOptions.formData = formData
    drawOptions.title = "修改物品型号"
    isOpenDrawer.value = true
}
</script>

<style lang="scss" scoped>
.rebeccapurple{
   
    font-weight: bold;
    text-align: center;
    
}
.red{
    
    color: #fff;
    text-align: center;
   
}
</style>