<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        待解冻资金列表
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #state="scoped">

            <div>
                {{ scoped.row.state == 1 ? '待解冻' : '已解冻' }}
            </div>
        </template>
        <template #type="scoped">

            <div>
                {{ scoped.row.type == 1 ? '默认' : '次月提现' }}
            </div>
        </template>
        <template #caozuo="scoped">

            <el-button v-if="scoped.row.state == 1" @click="jiedon(scoped.row)">解冻</el-button>
            <el-button v-if="scoped.row.state == 2" @click="jiedon(scoped.row,true)">重新冻结</el-button>
        </template>
        <template #notIn="scoped">

            <div>
                {{ scoped.row.notIn  ? '是' : '已转走' }}
            </div>
        </template>


    </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useFreezeCash } from "@/use/finance"
import { jiedongForId } from "@/api/finance"
import { ElMessage } from 'element-plus';
const { search, searchData, tableObjet, headers } = useFreezeCash()

const jiedon = (item,isccdj=false) => {

    jiedongForId({ id: item.Id,isccdj }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>
<style lang="scss" scoped></style>