
import {$post} from "./base"
export async function addBillingRule(params){
   const data= await $post("/billingrule/add",params)
   return data;
}
export async function getOne(item_model_id){
    let params=new FormData();
    params.append('item_model_id',item_model_id);
    const data= await $post("/billingrule/getone",params)
    return data;
 }

 export async function getBillingRules(params){
   
   
    return $post("/billingrule/getBillingRules",params)
 }
 export async function getBillingRulesOptions(store_id){
  
  
   return $post("/billingrule/getBillingRulesOptions",{store_id})
}
export function updateBillingRule(params){
return $post("/billingrule/updateBillingRule",params)
}

 
 
