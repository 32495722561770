import {$post} from "./base"
export async function addGoods(params){
    return $post("/goods/add",params)
}

export async function getGoodsList(params){
    return $post("/goods/getGoodsList",params)
}
export async function getBatteryList(params){
    return $post("/goods/getBatteryList",params)
}

export async function getGoodsListToOption(){
    return $post("/goods/getGoodsListToOption")
}

export async function addBatteryForGoodsId(params){
    return $post("/goods/addBatteryForGoodsId",params)
}
export async function getColorList(params){
    return $post("/goods/getColorList",params)
}

export async function addColorForGoodsId(params){
    return $post("/goods/addColorForGoodsId",params)
}
export async function bannerList(){
    return $post("/goods/bannerList")
}
export async function addBanner(parmas){
    return $post("/goods/addBanner",parmas)
}
export async function edit(parmas){
    return $post("/goods/edit",parmas)
}
export async function remove(parmas){
    return $post("/goods/remove",parmas)
}
export async function getShopOrderList(parmas){
    return $post("/goods/getShopOrderList",parmas)
}

export async function fahuo(parmas){
    return $post("/goods/fahuo",parmas)
}

export async function updateGoodsInfo(parmas){
    return $post("/goods/updateGoodsInfo",parmas)
}
export async function swtichGoodsStateBuId(id){
    return $post("/goods/swtichGoodsStateBuId",{id})
}








