import {$post} from "@/api/base"

export function index(params){
    return $post("/jms/index",params)
}
export function tx(params){
    return $post("/jms/tx",params)
}
export function cashFlow(params){
    return $post("/jms/zjls",params)
}