<template>
    <div>
            <FormComponent :data="formData" title="添加账户" canEdit="trues" @submit="submit">

            </FormComponent>
    </div>
</template>

<script  setup>
import FormComponent from '@/components/FormComponent.vue';
import {useAddAdmin} from "@/use/admin"
const {formData,submit}=useAddAdmin();
</script>

<style scoped lang="scss">
::v-deep .cell{
    text-align: center;
}
</style>
