<template>
    <div class="title">
        出租率统计
    </div>
    <XIASearchBar :searchData="searchData" @search="search" @daochu="search(true)"/>

    <div>
        <TableComponent :headers="headers" :tableObjet="tableObjet" :height="750">
            <template #percent="s">
            
            {{Number((s.row.leaseIng/s.row.total)*100).toFixed(0)}}%
            </template>
            <template #all_percent="s">
            
            {{Number((s.row.now/s.row.total)*100).toFixed(0)}}%
            </template>

            
        
        </TableComponent>
    </div>
</template>

<script setup>

import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';

import {useLeaseData} from "@/use/statistics"

const {search,searchData,tableObjet,headers}=useLeaseData()

</script>

<style lang="scss" scoped>

</style>