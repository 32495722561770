import {$post} from "./base"

export async function shouhouIndex(data){

    return $post("/shouhou/shouhouIndex",data)
}

export async function chuli(data){

    return $post("/shouhou/chuli",data)
}
export async function bohui(data){

    return $post("/shouhou/bohui",data)
}
