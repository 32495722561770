<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">商户资金流水</div>
        <TableComponent :tableObjet="tableObjet" :headers="headers">
            <template #price="scope">
                <el-tag  class="price"> {{ scope.row.price }}</el-tag>
            </template>
            <template #balance_pre="scope">
                <el-tag> {{ scope.row.balance_pre }}</el-tag>
            </template>
            <template #balance_post="scope">
                <el-tag> {{ scope.row.balance_post }}</el-tag>
            </template>
            <template #type="scope">
                <el-tag :class="'type' + scope.row.type"> {{ getType(scope.row.type) }}</el-tag>
            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useAdminCahFlow } from "@/use/finance"

const { searchData, headers, tableObjet, search } = useAdminCahFlow();

const getType = (type) => {
    const types = ["", "收取押金", "收取租金", "押金退回", "平台分成", "提现申请", "提现驳回"]

    if(type==20){
        return "租金解冻"
    }
    if(type==21){
        return "账号清零"
    }
    if(type==22){
        return "资金修改"
    }
    if(type==123||type==111){
        return "租金退回"
    }
    return types[type]
}
</script>

<style lang="scss" scoped>
.type4 {
    padding: 0 10px;
    background-color: #6a6;
    color: #fff;
    
}

.type2,.type20 {
    padding: 0 10px;
    background-color: #66f;
    color: #fff;
   
}

.type5 {
    padding: 0 10px;
    background-color: #666;
    color: #fff;
    
}

.type6 {
    padding: 0 10px;
    background-color: #faa;
    color: #fff;
   
}


.price{
    
    
    color: #f00;
    
}
</style>