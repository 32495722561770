<template>
   <XIA-SearchBar :searchData="searchData" @search="search"/>
   <div class="title">财务总览</div>

   <div class="flex zonlan">
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    总
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{tableObjet.otherData.totalBalance}}</div>
                    <div>
                        总营收
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    店
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{tableObjet.otherData.storeTotalBalance}}</div>
                    <div>
                        门店总营收
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    可
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{tableObjet.otherData.ketixian}}</div>
                    <div>
                        可提现总额
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    已
                </div>
                <div class="flex_1 c">
                    <div class="price">￥{{tableObjet.otherData.yitixian}}</div>
                    <div>
                        已提现总额
                    </div>
                </div>
            </div>
    </div>
    <div class="flex zonlan">
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    单
                </div>
                <div class="flex_1 c">
                    <div class="price">{{tableObjet.otherData.totalOrderCount}}</div>
                    <div>
                        总订单
                    </div>
                </div>
            </div>
            <!-- <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    物
                </div>
                <div class="flex_1 c">
                    <div class="price">{{tableObjet.otherData.totalOrderCount}}</div>
                    <div>
                        租赁物品次数
                    </div>
                </div>
            </div> -->
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    申
                </div>
                <div class="flex_1 c">
                    <div class="price">{{tableObjet.otherData.cashApplyCount}}</div>
                    <div>
                        申请提现次数
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    率
                </div>
                <div class="flex_1 c">
                    <div class="price">{{Number(tableObjet.otherData.chuzulv).toFixed(2)}}%</div>
                    <div>
                        总出租率
                    </div>
                </div>
            </div>
            <div class="flex_1 item flex">
                <div class="zs">

                </div>
                <div class="b">
                    店
                </div>
                <div class="flex_1 c">
                    <div class="price">{{tableObjet.otherData.storeCount}}</div>
                    <div>
                        总门店数
                    </div>
                </div>
            </div>
    </div>
    <div style="margin-top: 20px;">

    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

    </TableComponent>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useOverView} from "@/use/overView"
const {search,searchData,tableObjet,headers}=useOverView()
</script>

<style scoped lang="scss">
.zonlan {
    flex: 1;
    width: 100%;
    margin-top: 20px;
display: flex;
    .item {
        background-color: #fff;
        height: 100px;
        border-radius: 5px;
        margin: 0 20px;
        
        align-items: center;

        .zs {
            background-color: var(--el-color-primary);
            width: 10px;
            border-radius: 5px;
            margin-right: 50px;
            height: 100%;
        }
        .b{
            color:#999;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border:5px solid var(--el-color-primary);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .c{
            text-align: center;
            color: #999;
            font-size: 13px;
            .price{
                margin-bottom: 10px;
                color: var(--el-color-primary);
                font-size: 25px;
            }
        }
    }

}
</style>
