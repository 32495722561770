<template>

                    <div class="center">
                        <div class="number">
                            404
                        </div>
                        <div class="tip">
                            页面不存在
                        </div>
                        <RouterLink to="/">返回控制台</RouterLink>
                        


                    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">

.center{
    text-align: center;
    .number{
        font-size: 60px;


    }
    .tip{
        color: #aaa;
    }
}

a{
    

}
</style>